import axios from "axios";
import { getEndpoints } from "./Constants";

export const fetchCategories = async () => {
  const response = await axios.get(getEndpoints.getAllCategory + "?limit=1000");

  return response.data;
};

export const fetchProducts = async (subId, catID) => {
  const response = await axios.get(
    getEndpoints.getAllProducts + `?subCatId=${subId}&catId=${catID}`
  );

  return response.data;
};

export const fetchSubCategories = async () => {
  const response = await axios.get(
    getEndpoints.getAllSubCategories + "?limit=1000"
  );

  return response.data;
};

export const fetchCategoriesSubCategory = async () => {
  const response = await axios.get(getEndpoints.getAllCategoriesSubCategories);

  return response.data;
};

export const fetchSingleProduct = async (id) => {
  const response = await axios.get(getEndpoints.getAllSingleProduct + id);

  return response.data;
};

export const fetchCertificates = async () => {
  const response = await axios.get(getEndpoints.getAllCertificates);

  return response.data;
};

export const fetchAllHomeScreens = async () => {
  const response = await axios.get(getEndpoints.getAllHomeScreens);

  return response.data;
};

export const fetchAllServices = async () => {
  const response = await axios.get(getEndpoints.getAllServices);

  return response.data;
};

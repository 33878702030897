/** @format */

import React, { useEffect, useState, useLayoutEffect } from "react";
import { Wrapper } from "./ProductDetail.style";
import { Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import { Card } from "../../components";
import { useParams } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { fetchSingleProduct } from "../../utils/APIUrls";
import { IMG_URL } from "../../utils/Constants";
import Modal from "../../components/Modal/Modal";
import { useMainContext } from "../../context/context";
import axios from "axios";
import { getEndpoints } from "../../utils/Constants";

const ProductDetail = () => {
  const [similarProduct, setSimilarProduct] = useState();
  const [productData, setProductData] = useState({});

  const { id } = useParams();

  // console.log(similarProduct)

  const { data: singleProduct } = useQuery({
    queryKey: ["singleProduct", id],
    queryFn: () => fetchSingleProduct(id)
  });

  const [showImage, setShowImage] = useState();
  // const [sendDataModal, setSendDataModal] =useState();

  // console.log(singleProduct);
  const { showModal, setShowModal, setId } = useMainContext();

  const catId = singleProduct?.data?.category_id;
  const subcat = singleProduct?.data?.sub_category_id;

  useLayoutEffect(() => {
    setShowImage(IMG_URL + singleProduct?.data?.images[0]);
    window.scrollTo(0, document.body.scrollTop);
  }, [singleProduct, similarProduct]);

  useEffect(() => {
    setId(id);
  }, []);

  const fetchSimilarProduct = async (catId, subcat) => {
    const response = await axios.post(
      getEndpoints.getAllSimilarProduct + `?catId=${catId}&subcat=${subcat}`
    );

    setSimilarProduct(response.data);
  };

  useEffect(() => {
    if (catId !== undefined && subcat !== undefined) {
      fetchSimilarProduct(catId, subcat);
    }
  }, [catId, subcat]);

  useEffect(() => {
    window.scrollTo(0, document.body.scrollTop);
  }, []);

  // console.log(singleProduct?.data?.detail_description)
  // console.log(singleProduct?.data?.images);

  // const many = singleProduct?.data?.images
  const noOfImages = singleProduct?.data?.images.length;

  let [show, setShow] = useState(false);

  useEffect(() => {
    show = noOfImages <= 4;
    setShow(show);
  }, [noOfImages]);

  // console.log(show)

  const detailDesc = singleProduct?.data?.detail_description;

  return (
    <Wrapper show={show}>
      <div className="product-detail-page">
        <div className="pageNavigation">
          <h3>{singleProduct?.data?.product_name}</h3>
        </div>
        <div className="first-section">
          <div className="first-section-left">
            <div className="first-section-left-left">
              {singleProduct?.data?.images.map((curElem, index) => {
                return (
                  <div key={index}>
                    <img
                      src={IMG_URL + curElem}
                      alt="productImage"
                      onClick={() => setShowImage(IMG_URL + curElem)}
                    />
                  </div>
                );
              })}
            </div>
            <div className="first-section-left-right">
              <img src={showImage} alt="productImage" />
            </div>
          </div>

          <div className="first-section-right">
            <div className="first-section-right-title">
              <h3>
                {singleProduct?.data?.product_name
                  ? singleProduct?.data?.product_name
                  : "N/A"}
              </h3>
            </div>
            <div className="first-section-right-price-star">
              <div className="first-section-right-price">
                <h3>{`₹ ${
                  singleProduct?.data?.product_price
                    ? singleProduct?.data?.product_price
                    : "N/A"
                }`}</h3>
              </div>
              {/* <div className="first-section-right-star">
                <h3>{`⭐ ${
                  singleProduct?.data?.product_avg_rate
                    ? singleProduct?.data?.product_avg_rate
                    : "N/A"
                } Ratings`}</h3>
              </div> */}
            </div>
            <div className="first-section-right-details">
              <div className="grid-container">
                <div className="item">
                  <h5>Material</h5>
                </div>
                <div className="item">
                  <h5>
                    {singleProduct?.data?.short_details.material
                      ? singleProduct?.data?.short_details.material
                      : "N/A"}
                  </h5>
                </div>
                <div className="item">
                  <h5>Usage/Application</h5>
                </div>
                <div className="item">
                  <h5>
                    {singleProduct?.data?.short_details.usage_application
                      ? singleProduct?.data?.short_details.usage_application
                      : "N/A"}
                  </h5>
                </div>
                <div className="item">
                  <h5>Roughness</h5>
                </div>
                <div className="item">
                  <h5>
                    {singleProduct?.data?.short_details.roughness
                      ? singleProduct?.data?.short_details.roughness
                      : "N/A"}
                  </h5>
                </div>
                <div className="item">
                  <h5>Color</h5>
                </div>
                <div className="item">
                  <h5>
                    {singleProduct?.data?.short_details.colour
                      ? singleProduct?.data?.short_details.colour
                      : "N/A"}
                  </h5>
                </div>
                <div className="item">
                  <h5>Paper Size</h5>
                </div>
                <div className="item">
                  {" "}
                  <h5>
                    {singleProduct?.data?.short_details.paper_size
                      ? singleProduct?.data?.short_details.paper_size
                      : "N/A"}
                  </h5>
                </div>
                <div className="item">
                  <h5>Product GSM</h5>
                </div>
                <div className="item">
                  <h5>
                    {singleProduct?.data?.short_details.product_gsm
                      ? singleProduct?.data?.short_details.product_gsm
                      : "N/A"}
                  </h5>
                </div>
              </div>
            </div>
            <div className="first-section-right-button">
              <button
                onClick={() => {
                  setShowModal(true);
                  setProductData(singleProduct?.data);
                }}
              >
                Get Latest Price
              </button>
            </div>
          </div>
        </div>
        {/* {showModal && <Modal product={singleProduct?.data} />} */}
        {showModal && <Modal product={productData} />}


        {/* ============Second Section============ */}

        <div className="second-section">
          <div className="second-section-title">
            <h3>Details</h3>
          </div>
          <div className="second-section-columns">
            <div dangerouslySetInnerHTML={{ __html: detailDesc }} />
          </div>
        </div>

        {/* ============third-section================ */}

        <div className="third-section">
          <div className="third-section-title">
            <h3>Similar Products</h3>
          </div>
          <div className="third-section-slider">
            {similarProduct && (
              <Swiper
                navigation={false}
                spaceBetween={30}
                autoplay={{
                  delay: 2500,
                  disableOnInteraction: false,
                  pauseOnMouseEnter: true
                }}
                breakpoints={{
                  375: {
                    slidesPerView: 1,
                    spaceBetween: 20
                  },
                  1024: {
                    slidesPerView: 4,
                    spaceBetween: 30
                  },
                  999: {
                    slidesPerView: 3,
                    spaceBetween: 20
                  },
                  280: {
                    slidesPerView: 1,
                    spaceBetween: 30
                  }
                }}
                modules={[Autoplay]}
                slidesPerView={4}
                className="mySwiper"
              >
                {similarProduct?.data?.docs.map((curElem, index) => {
                  // console.log(curElem)
                  return (
                    <SwiperSlide key={index}>
                      <div onClick={() => setProductData(curElem)}>
                        {<Card product={curElem} />}
                      </div>
                    </SwiperSlide>
                  );
                })}
              </Swiper>
            )}
          </div>
        </div>
      </div>
    </Wrapper>
  );
};

export default ProductDetail;

import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { MobileFilterContainer, MobFilterStyle } from "./mobilefilter.style";
import { fetchCategories, fetchSubCategories } from "../../utils/APIUrls";
import { getEndpoints } from "../../utils/Constants";
import { useQuery } from "@tanstack/react-query";
import { RiSortDesc } from "react-icons/ri";
import { VscSettings } from "react-icons/vsc";
import queryString from "query-string";
import axios from "axios";
import Slider from "rc-slider/lib/";
import "rc-slider/assets/index.css";

import {
  MdOutlineCheckBox,
  MdOutlineCheckBoxOutlineBlank,
} from "react-icons/md";

const MobileFIlter = ({ setProductList }) => {
  const [sortOpen, setSortOpen] = useState(false);
  const [filterOpen, setFilterOpen] = useState(false);

  let location = queryString.parse(window.location.search);

  const navigate = useNavigate();

  const { subid } = useParams();

  const { data: categories } = useQuery({
    queryKey: ["categories"],
    queryFn: () => fetchCategories(),
  });

  const { data: subcategories } = useQuery({
    queryKey: ["subcategories"],
    queryFn: () => fetchSubCategories(),
  });

  const fetchAllProducts = async () => {
    const apiURL = queryString.stringify(location);

    try {
      const response = await axios.get(
        `${getEndpoints.getAllProducts}?subCatId=${subid || ""}&${apiURL}`
      );

      if (response.data.status === "success") {
        setProductList(response.data.data.docs);
      }
    } catch (error) {
      console.log(error);
    }
  };

  // Category Filter
  const handleIsCatChecked = (catid) => {
    if (location["catId[]"]) {
      if (typeof location["catId[]"] === "string") {
        return location["catId[]"] === catid;
      } else {
        return location["catId[]"].includes(catid);
      }
    }
  };

  const handleCategoryFilter = (id) => {
    const prevURL = queryString.stringify(location);

    if (!location["catId[]"]) {
      location["catId[]"] = id;
      navigate("?" + prevURL + "&catId[]=" + id);
    } else {
      if (typeof location["catId[]"] === "string") {
        if (location["catId[]"] === id) {
          delete location["catId[]"];
        } else {
          location["catId[]"] = [location["catId[]"], id];
        }
      } else {
        const isCat = location["catId[]"].find((cat) => cat === id);
        if (isCat) {
          const idx = location["catId[]"].indexOf(id);
          location["catId[]"].splice(idx, 1);
        } else {
          location["catId[]"].push(id);
        }
      }
    }

    const catString = queryString.stringify(location);
    navigate("?" + catString);

    fetchAllProducts(location);
  };

  // Sub CategoryFilter
  const handleIsSubCatChecked = (catid) => {
    if (location["subCatId[]"]) {
      if (typeof location["subCatId[]"] === "string") {
        return location["subCatId[]"] === catid;
      } else {
        return location["subCatId[]"].includes(catid);
      }
    }
  };

  const handleSubCategoryFilter = (id) => {
    const prevURL = queryString.stringify(location);

    if (!location["subCatId[]"]) {
      location["subCatId[]"] = id;
      navigate("?" + prevURL + "&subCatId[]=" + id);
    } else {
      if (typeof location["subCatId[]"] === "string") {
        if (location["subCatId[]"] === id) {
          delete location["subCatId[]"];
        } else {
          location["subCatId[]"] = [location["subCatId[]"], id];
        }
      } else {
        const isCat = location["subCatId[]"].find((cat) => cat === id);
        if (isCat) {
          const idx = location["subCatId[]"].indexOf(id);
          location["subCatId[]"].splice(idx, 1);
        } else {
          location["subCatId[]"].push(id);
        }
      }
    }

    const subCatString = queryString.stringify(location);
    navigate("?" + subCatString);

    fetchAllProducts(location);
  };

  const handleSliderChange = (event) => {
    if (event) {
      location["price_min"] = event[0];
      location["price_max"] = event[1];
      let prevUrl = queryString.stringify(location);
      navigate("?" + prevUrl);
      fetchAllProducts(location);
    }
  };

  const marks = {
    // 0: location["price_min"] ? "₹" + location["price_min"] : "₹0",
    // 200000: location["price_max"] ? "₹" + location["price_max"] : "₹200000",
    1000: 1000,
    20000: 20000,
    60000: 60000,
    100000: 100000,
  };

  return (
    <MobileFilterContainer
      onMouseLeave={() => {
        setFilterOpen(false);
      }}
    >
      <div className="sort-filter-container">
        {/* <div
          className="sort"
          onClick={() => setSortOpen((prevState) => !prevState)}
        >
          <strong>Sort</strong>
          <RiSortDesc color="#ffffff" size={20} />
        </div> */}

        {/* <div className="div-line"></div> */}

        <div
          className="filter"
          onClick={() => setFilterOpen((prevState) => !prevState)}
        >
          <VscSettings color="#ffffff" size={20} />
          <strong>Filter</strong>
        </div>
      </div>
      {filterOpen && (
        <MobFilterStyle>
          <div className="category-filter-mob">
            <h1>Filter</h1>

            <div className="divider"></div>

            <div className="categories-container">
              <h2>Categories</h2>

              <div className="category-wrapper">
                {categories?.data?.docs?.map((category) => (
                  <div
                    className="category-content"
                    key={category._id}
                    onClick={() => handleCategoryFilter(category?._id)}
                  >
                    {handleIsCatChecked(category?._id) ? (
                      <MdOutlineCheckBox color="#787878" size={25} />
                    ) : (
                      <MdOutlineCheckBoxOutlineBlank
                        color="#787878"
                        size={25}
                      />
                    )}

                    <h3>{category.title}</h3>
                  </div>
                ))}
              </div>
            </div>

            <div className="divider"></div>

            <div className="sub-categories-container">
              <h2>Sub Categories</h2>
              <div className="sub-category-wrapper">
                {subcategories?.data?.docs?.map((subcat) => (
                  <div
                    className="sub-cat-content"
                    key={subcat?._id}
                    onClick={() => handleSubCategoryFilter(subcat?._id)}
                  >
                    {handleIsSubCatChecked(subcat?._id) ? (
                      <MdOutlineCheckBox color="#787878" size={25} />
                    ) : (
                      <MdOutlineCheckBoxOutlineBlank
                        color="#787878"
                        size={25}
                      />
                    )}
                    <h3>{subcat?.title}</h3>
                  </div>
                ))}
              </div>
            </div>

            <div className="divider"></div>
            {/* 
            <div className="price-container">
              <h2>Price</h2>

              <div className="price-content-wrapper">
                <Slider
                  range
                  min={1000}
                  max={100000}
                  marks={marks}
                  step={null}
                  defaultValue={[1000, 100000]}
                  onChange={handleSliderChange}
                />
              </div>
            </div> */}
          </div>
        </MobFilterStyle>
      )}
    </MobileFilterContainer>
  );
};

export default MobileFIlter;

/** @format */

import React, { useEffect, useState } from "react";
import { Autoplay, Pagination } from "swiper";
import { Wrapper } from "./card.style";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { IMG_URL } from "../../utils/Constants";
import { Link } from "react-router-dom";
import { useMainContext } from "../../context/context";

const Card = ({ product }) => {
  const { setShowModal } = useMainContext();
  const [swiper, setSwiper] = useState(null);


  // console.log(swiper);

  const cardslideStop = () => {
    swiper.autoplay.stop();
    // console.log("stop")
  };
  const cardslideStart = () => {
    swiper.autoplay.start();
    // console.log("start")
  };



  useEffect(() => {
    window.scrollTo(0, document.body.scrollTop);
    if(swiper){
      cardslideStop();
    }
  }, []);

  const rupee_format = (str) => {
    if (str) {
      var x = str;
      x = x.toString();
      var lastThree = x.substring(x.length - 3);
      var otherNumbers = x.substring(0, x.length - 3);
      if (otherNumbers !== "") lastThree = "," + lastThree;
      var res = otherNumbers.replace(/\B(?=(\d{2})+(?!\d))/g, ",") + lastThree;
      return res;
    } else {
      return;
    }
  };

  // console.log(product?.images)


  useEffect(() => {
    if(swiper){
      cardslideStop();
    }
  },[product?._id])


  return (
      <Wrapper>
        <div
          className="card"
          onMouseEnter={cardslideStart}
          onMouseLeave={cardslideStop}
          onClick={cardslideStop}
        >
    <Link to={`/productdetail/${product?._id}`}>

          <div className="card-img">
            
              <Swiper
                pagination={true}
                modules={[Autoplay, Pagination]}
                slidesPerView={1}
                onSwiper={setSwiper}
                className="mySwiper"
              >
                {product?.images.map((curElem, index) => {
                  return (
                    <SwiperSlide
                      key={index}
                      data-swiper-autoplay="2000"
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <img src={IMG_URL + curElem} alt="" />
                    </SwiperSlide>
                  );
                })}
              </Swiper>
            
          </div>
          </Link>

          <div className="card-title">
            <h3>{product?.product_name || "68 GSM Xerox A4 Size Paper"}</h3>
          </div>
          <div className="card-price">
            <h3>
              {"₹ " + rupee_format(product?.product_price) || "₹ 220/ Ream"}
            </h3>
          </div>
          {/* <div className="card-rating">
            <h3>{`⭐ ${product?.product_avg_rate} Ratings `}</h3>
          </div> */}
          <div className="card-button">
            <button onClick={() => setShowModal(true)}>Enquiry Now</button>
          </div>
        </div>
      </Wrapper>
    
  );
};

export default Card;

/** @format */

import styled from "styled-components";

export const Wrapper = styled.section`
  .product-detail-page {
    margin-top: 5rem;
    .pageNavigation {
      padding: 1rem 3rem;
      h3 {
        color: var(--black-color);
        font-size: 1rem;
        font-weight: 400;
      }
    }
    .first-section {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0 3rem;
      gap: 2rem;
      .first-section-left {
        flex-direction: row;
        display: flex;
        align-items: center;
        border: 2px solid var(--white-color);
        border-radius: 7px;
        box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.1);
        width: 60%;
        height: 400px;
        gap: 3rem;
        padding: 0 2rem;
        .first-section-left-left {
          /* justify-content: ${(props) =>
            props.show ? "center" : "unset"}; */
          display: flex;
          flex-direction: column;
          gap: 2rem;
          overflow-y: auto;
          height: 400px;
          align-items: center;
          /* width: 100px; */
          overflow-x: hidden;
          padding: 1rem 0 1rem 0;
          &::-webkit-scrollbar {
            width: 3px;
          }

          &::-webkit-scrollbar-thumb {
            background-color: #d9d9d9;
            border-radius: 6px;
          }

          div {
            margin-left: 10px;
            margin-right: 10px;
            img {
              border: 1px solid #ffffff;
              box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.1);
              border-radius: 7px;
              display: flex;
              justify-content: center;
              align-items: center;
              object-fit: cover;
              height: 4rem;
              width: 4rem;
              /* width: 4rem;  */
              object-fit: cover;
              /* aspect-ratio: 16/9; */
              cursor: pointer;
            }
          }
        }
        .first-section-left-right {
          height: 24.5rem;
          width: 40rem;
          display: flex;
          justify-content: center;
          align-items: center;
          img {
            height: 24rem;
            width: 100%;
            object-fit: cover;
          }
        }
      }
      .first-section-right {
        display: flex;
        align-items: center;
        /* justify-content: center; */
        flex-direction: column;
        width: 40%;
        border: 2px solid var(--white-color);
        border-radius: 7px;
        box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.1);
        flex-wrap: wrap;
        height: 25rem;
        position: relative;
        .first-section-right-title {
          flex: none;
          width: 100%;
          h3 {
            color: var(--gray-color);
            font-weight: 400;
            font-size: 1.3rem;
            padding: 1rem 1rem;
            margin: 0 0 0 0;
          }
        }
        .first-section-right-price-star {
          display: flex;
          justify-content: space-between;
          align-items: center;
          border-bottom: 1px solid #e7e7e7;
          width: 95%;
          padding: 0 0.5rem 0.5rem 0.8rem;
          .first-section-right-price {
            h3 {
              font-weight: 500;
              font-size: 1.2rem;
              color: var(--black-color);
            }
          }
          .first-section-right-star {
            h3 {
              font-weight: 400;
              font-size: 0.8rem;
              color: #6c6c6c;
            }
          }
        }
        .first-section-right-details {
          display: flex;
          align-items: center;
          justify-content: space-between;
          width: 95%;
          padding: 0.8rem 0.5rem 0.5rem 0.8rem;
          border-bottom: 1px solid #e7e7e7;
          .grid-container {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            grid-gap: 0.5rem;
            width: 100%;
            .item {
              /* padding: 10px; */
              text-align: left;
              h5 {
                font-size: 0.8rem;
                font-weight: 400;
                color: #6c6c6c;
                margin: 0.1rem 0;
              }
            }
          }
        }
        .first-section-right-button {
          width: 100%;
          padding: 1rem 1rem;
          position: absolute;
          bottom: 0;
          button {
            background-color: var(--blue-color);
            box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.1);
            border-radius: 7px;
            width: 100%;
            padding: 1rem 0;
            border: none;
            color: var(--white-color);
            font-weight: 500;
            font-size: 1rem;
            cursor: pointer;
          }
        }
      }
    }

    /* =========second-section======== */

    .second-section {
      display: flex;
      justify-content: center;
      flex-direction: column;
      padding: 0 2rem 1rem 2rem;
      box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.1);
      border-radius: 7px;
      margin: 2rem 3rem;
      .second-section-title {
        display: flex;
        width: 100%;
        align-items: center;
        h3 {
          color: var(--blue-color);
          padding: 1rem 0;
        }
      }
      .second-section-columns {
        display: flex;
        justify-content: center;
        gap: 2rem;
        .second-section-column-1 {
          width: 25%;
          display: flex;
          flex-direction: column;
          .second-section-column-title {
            h3 {
              font-weight: 400;
              font-size: 1rem;
              color: #2e2e2e;
            }
          }
          .second-section-column-content {
            h3 {
              font-weight: 400;
              font-size: 1rem;
              color: #6c6c6c;
              padding: 1rem 0;
            }
          }
        }
        .second-section-column-2 {
          width: 20%;

          .second-section-column-title {
            h3 {
              font-weight: 400;
              font-size: 1rem;
              color: #2e2e2e;
            }
          }
          .second-section-column-content {
            h3 {
              font-weight: 400;
              font-size: 1rem;
              color: #6c6c6c;
              padding: 1rem 0;
            }
          }
        }
        .second-section-column-3 {
          width: 20%;

          .second-section-column-title {
            h3 {
              font-weight: 400;
              font-size: 1rem;
              color: #2e2e2e;
            }
          }
          .second-section-column-content {
            h3 {
              font-weight: 400;
              font-size: 1rem;
              color: #6c6c6c;
              padding: 1rem 0;
            }
          }
        }
        .second-section-column-4 {
          width: 20%;

          .second-section-column-title {
            h3 {
              font-weight: 400;
              font-size: 1rem;
              color: #2e2e2e;
            }
          }
          .second-section-column-content {
            h3 {
              font-weight: 400;
              font-size: 1rem;
              color: #6c6c6c;
              padding: 1rem 0;
            }
          }
        }
        .second-section-column-5 {
          width: 20%;

          .second-section-column-title {
            h3 {
              font-weight: 400;
              font-size: 1rem;
              color: #2e2e2e;
            }
          }
          .second-section-column-content {
            h3 {
              font-weight: 400;
              font-size: 1rem;
              color: #6c6c6c;
              padding: 1rem 0;
            }
          }
        }
      }
    }

    /* ===========third-section================ */

    .third-section {
      padding: 0 0rem;
      padding: 0rem 1rem 1rem 1rem;
      margin: 1rem 1rem 2rem 1rem;
      .third-section-title {
        h3 {
          color: var(--blue-color);
          padding: 1rem 0 0 1rem;
          font-size: 2rem;
        }
      }
      .third-section-slider {
      }
    }
  }

  /* Responsive */

  @media only screen and (max-width: 1240px) {
    .product-detail-page {
      .first-section {
        gap: 3rem;
        .first-section-left {
          height: 23.5rem;
          .first-section-left-left {
          }
          .first-section-left-right {
            padding: 3rem 0;
            img {
              height: auto;
              width: 21rem;
              object-fit: cover;
            }
          }
        }
        .first-section-right {
          width: 30%;
          .first-section-right-title {
            h3 {
              font-size: 1rem;
            }
          }
          .first-section-right-price-star {
            .first-section-right-price {
              h3 {
                font-size: 1rem;
              }
            }
          }
        }
      }
    }
  }

  @media only screen and (max-width: 950px) {
    .product-detail-page {
      .first-section {
        gap: 3rem;
        .first-section-left {
          flex-direction: column-reverse;
          gap: 1rem;
          padding: 1rem 0;
          .first-section-left-left {
            flex-direction: row;
            width: 100%;
            overflow-x: auto;
          }
          .first-section-left-right {
            padding: 0rem 0;
            img {
              height: auto;
              width: 16rem;
              object-fit: cover;
            }
          }
        }
        .first-section-right {
          width: 35%;
          .first-section-right-title {
            h3 {
              font-size: 1rem;
            }
          }
          .first-section-right-price-star {
            .first-section-right-price {
              h3 {
                font-size: 1rem;
              }
            }
          }
        }
      }
    }
  }
  @media only screen and (max-width: 850px) {
    .product-detail-page {
      .first-section {
        gap: 3rem;
        .first-section-left {
          flex-direction: column-reverse;
          .first-section-left-left {
            flex-direction: row;
          }
          .first-section-left-right {
            img {
              height: auto;
              width: 16rem;
              object-fit: cover;
            }
          }
        }
        .first-section-right {
          width: 35%;
          .first-section-right-title {
            h3 {
              font-size: 0.8rem;
            }
          }
          .first-section-right-price-star {
            .first-section-right-price {
              h3 {
                font-size: 0.8rem;
              }
            }
            .first-section-right-star {
              h3 {
                font-size: 0.5rem;
              }
            }
          }
        }
      }
    }
  }

  @media only screen and (max-width: 690px) {
    .product-detail-page {
      .pageNavigation {
        display: none;
      }
      .first-section {
        flex-direction: column;
        gap: 1rem;
        padding: 1rem 1rem 0 1rem;
        .first-section-left {
          flex-direction: column-reverse;
          width: 100%;
          border: none;
          box-shadow: none;
          .first-section-left-left {
            justify-content: unset;
            flex-direction: row;
            gap: 2.1rem;
          }
          .first-section-left-right {
            width: 100%;
            display: flex;
            justify-content: center;
            border: 2px solid var(--white-color);
            border-radius: 7px;
            box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.1);
            img {
              height: 16rem;
              width: 100%;
              object-fit: cover;
            }
          }
        }
        .first-section-right {
          width: 100%;
          border: none;
          box-shadow: none;
          .first-section-right-title {
            border-top: 1px solid #e7e7e7;
            padding-left: 0rem;
            h3 {
              font-size: 1rem;
              padding: 1rem 0 1rem 0;
            }
          }
          .first-section-right-price-star {
            padding: 0 0rem 0.5rem 0rem;
            width: 100%;
            .first-section-right-price {
              h3 {
                font-size: 1rem;
              }
            }
            .first-section-right-star {
              h3 {
                font-size: 0.6rem;
              }
            }
          }
          .first-section-right-details {
            width: 100%;
            padding: 0 1rem 0 0;
            .first-section-right-details-key {
              h5 {
                font-size: 1rem;
                font-weight: 400;
                color: #6c6c6c;
                margin: 1rem 0;
              }
            }
            .first-section-right-details-value {
              h5 {
                font-size: 1rem;
                font-weight: 400;
                color: #6c6c6c;
                margin: 1rem 0;
              }
            }
          }
          .first-section-right-button {
            padding: 1rem 0rem;
          }
        }
      }

      .second-section {
        display: flex;
        flex-direction: column;
        padding: 0 0rem 1rem 0rem;
        box-shadow: none;
        border-radius: 0px;
        margin: 1rem 1rem;

        .second-section-title {
          display: flex;
          width: 100%;
          align-items: center;
          h3 {
            color: var(--blue-color);
            padding: 1rem 0;
            font-size: 1.5rem;
          }
        }
        .second-section-columns {
          display: flex;
          justify-content: center;
          gap: 2rem;
          padding: 1rem 1rem;
          box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.1);
          border-radius: 7px;
          overflow-x: scroll;
          .second-section-column-1 {
            width: 150px;
            display: flex;
            flex-direction: column;
            .second-section-column-title {
              h3 {
                font-weight: 400;
                font-size: 1rem;
                color: #2e2e2e;
              }
            }
            .second-section-column-content {
              h3 {
                font-weight: 400;
                font-size: 1rem;
                color: #6c6c6c;
                padding: 1rem 0;
              }
            }
          }
          .second-section-column-2 {
            width: 20%;

            .second-section-column-title {
              h3 {
                font-weight: 400;
                font-size: 1rem;
                color: #2e2e2e;
              }
            }
            .second-section-column-content {
              h3 {
                font-weight: 400;
                font-size: 1rem;
                color: #6c6c6c;
                padding: 1rem 0;
              }
            }
          }
          .second-section-column-3 {
            width: 20%;

            .second-section-column-title {
              h3 {
                font-weight: 400;
                font-size: 1rem;
                color: #2e2e2e;
              }
            }
            .second-section-column-content {
              h3 {
                font-weight: 400;
                font-size: 1rem;
                color: #6c6c6c;
                padding: 1rem 0;
              }
            }
          }
          .second-section-column-4 {
            width: 150px;
            display: flex;
            flex-wrap: wrap;
            .second-section-column-title {
              h3 {
                font-weight: 400;
                font-size: 1rem;
                color: #2e2e2e;
              }
            }
            .second-section-column-content {
              h3 {
                font-weight: 400;
                font-size: 1rem;
                color: #6c6c6c;
                padding: 1rem 0;
              }
            }
          }
          .second-section-column-5 {
            width: 150px;

            .second-section-column-title {
              h3 {
                font-weight: 400;
                font-size: 1rem;
                color: #2e2e2e;
              }
            }
            .second-section-column-content {
              h3 {
                font-weight: 400;
                font-size: 1rem;
                color: #6c6c6c;
                padding: 1rem 0;
              }
            }
          }
        }
      }

      .third-section {
        padding: 0 0;
        .third-section-title {
          h3 {
            font-size: 1.5rem;
            padding-left: 0;
          }
        }
        .third-section-slider {
        }
      }
    }
  }
`;

/** @format */

const BASE_URL = "https://core.prglobalresources.com";
// const BASE_URL = "http://192.168.1.11:5001";
export const IMG_URL = "https://core.prglobalresources.com";
// export const IMG_URL = "http://192.168.1.11:5001";

export const getEndpoints = {
  getAllCategory: BASE_URL + "/main/category",
  getAllProducts: BASE_URL + "/main/product",
  getAllSubCategories: BASE_URL + "/main/sub-category",
  getAllCategoriesSubCategories: BASE_URL + "/main/category/categorySubCate",
  getAllSingleProduct: BASE_URL + "/main/product/",
  getAllSimilarProduct: BASE_URL + "/main/product/similar",
  getAllCertificates: BASE_URL + "/dash/certificate",
  getAllHomeScreens: BASE_URL + "/dash/home",
  getAllServices: BASE_URL + "/dash/service",
  getAllGetInTouch: BASE_URL + "/dash/webcontact",
  getAllWhoWeAre: BASE_URL + "/dash/whowe",
};

export const postEndpoints = {
  postContact: BASE_URL + "/api/contact/createContact",
  postSearch: BASE_URL + "/main/product/search",
  postEnquiry: BASE_URL + "/main/enquiry/genotp",
  postOtp: BASE_URL + "/main/enquiry/verifyOtp/",
  postResend: BASE_URL + "/main/enquiry/resendOtp",
};

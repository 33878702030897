/** @format */

import React, { useEffect, useState } from "react";
import { Wrapper } from "./footer.style";
import footerLogo from "../../assets/logo_black.png";
import { Link, useLocation } from "react-router-dom";
import callImg from "../../assets/phone-call-pr-icon.png";
import mailImg from "../../assets/email-pr-icon.png";
import linkedinImg from "../../assets/linkedin-pr-icon.png";
import facebookImg from "../../assets/facebook-pr-icon.png";

const Footer = () => {
  const [show, setShow] = useState(false);

  const { pathname } = useLocation();

  useEffect(() => {
    if (pathname === "/allproducts") {
      const show = pathname.includes("/allproducts");
      setShow(show);
    }
    if (pathname.includes("/products/")) {
      const show = pathname.includes("/products/");
      setShow(show);
    }
  }, [pathname]);

  // console.log(show);

  // useEffect(() => {
  //   // setMarginBottom(window.innerWidth);
  // }, [window.innerWidth]);

  return (
    <Wrapper show={show}>
      <div>
        <div className="footer">
          <div className="footer-column-1">
            <div className="footer-logo">
              <Link to={"/"}>
                <img src={footerLogo} alt="" />
              </Link>
            </div>
            <h5>
              We are an award-winning creative agency, dedicated to the best
              result in Import & Export, promotion, business consulting, and
              marketing.
            </h5>
          </div>
          <div className="footer-column-2">
            <div className="footer-links-header">
              <h3>Links</h3>
            </div>
            <div className="footer-links">
              <Link to={"/"}>
                <h5>Home</h5>
              </Link>
              <Link to={"/allproducts"}>
                <h5>Our Product</h5>
              </Link>
              <Link to={"/"}>
                <h5>Group of Companies</h5>
              </Link>
              <Link to={"/about"}>
                <h5>About Us</h5>
              </Link>
              <Link to={"/certificates"}>
                <h5>Certificates</h5>
              </Link>
              {/* <Link to={"/"}>
                <h5>Testimonials</h5>
              </Link>
              <Link to={"/"}>
                <h5>Our team</h5>
              </Link> */}
            </div>
          </div>
          <div className="footer-column-3">
            <div className="footer-address-header">
              <h3>Office Address</h3>
            </div>
            <div className="footer-address-address">
              <h5>
                Rukhamani Krishna", 607, 6th Floor, Nr Arjun Park,
                Besa-Beltarodi Road, Post Box No.440034, Nagpur, Maharashtra,
                India
              </h5>
            </div>
          </div>
          <div className="footer-column-4">
            <a href="#">
              <img src={callImg} alt="" />
            </a>
            <a href="#">
              <img src={mailImg} alt="" />
            </a>
            <a href="#">
              <img src={linkedinImg} alt="" />
            </a>
            <a href="#">
              <img src={facebookImg} alt="" />
            </a>
          </div>
        </div>
        <div className="footer-copyRight">
          <div className="footer-left">
            <h5>© 2019-2024 P.R.Global Resources All Rights Reserved</h5>
          </div>
          <div className="footer-right">
            <h5>
              <a
                style={{ textDecoration: "none", color: "#000" }}
                href="https://thespaceelement.com"
                target="_blank"
              >
                {" "}
                Design & Developed By The <span>Space</span> Element
              </a>
            </h5>
          </div>
        </div>
      </div>
    </Wrapper>
  );
};

export default Footer;

/** @format */

import styled from "styled-components";

export const Wrapper = styled.section`
  .footer {
    width: 100%;
    background-color: var(--faint-blue);
    padding: 3rem 3rem;
    display: flex;
    position: relative;
    gap: 8rem;
    .footer-column-1 {
      flex-direction: column;
      width: 25%;
      .footer-logo {
        img {
          height: auto;
          width: 12rem;
          object-fit: cover;
        }
      }
      h5 {
        padding: 1rem 0 0 0;
        font-size: 1rem;
        color: var(--gray-color);
        font-weight: 500;
      }
    }
    .footer-column-2 {
      width: 20%;
      .footer-links-header {
        h3 {
          margin: 1rem 0 1rem 0;
          color: var(--black-color);
          font-weight: 600;
          font-size: 1.5rem;
        }
      }
      .footer-links {
        a {
          text-decoration: none;
          h5 {
            padding: 0.4rem 0;
            color: var(--gray-color);
            font-size: 1rem;
          }
        }
      }
    }
    .footer-column-3 {
      width: 22%;
      .footer-address-header {
        h3 {
          margin: 1rem 0 1rem 0;
          color: var(--black-color);
          font-weight: 600;
          font-size: 1.5rem;
        }
      }
      .footer-address-address {
        h5 {
          color: var(--gray-color);
          font-size: 1rem;
        }
      }
    }
    .footer-column-4 {
      position: absolute;
      bottom: 1rem;
      display: flex;
      gap: 1rem;
      right: 3rem;
      img {
        width: 2rem;
      }
    }
  }
  .footer-copyRight {
    display: flex;
    justify-content: space-between;
    padding: 0 3rem;
    align-items: center;
    height: 2rem;
    .footer-left {
      h5 {
        font-weight: 500;
        font-size: 0.8rem;
        color: #2e2e2e;
      }
    }
    .footer-right {
      h5 {
        font-weight: 500;
        font-size: 0.6rem;
        color: #2e2e2e;
        span {
          color: rgb(236, 32, 36);
        }
      }
    }
  }

  /* Responsive */

  @media only screen and (max-width: 1315px) {
    .footer {
      gap: 4rem;
      .footer-column-1 {
        width: 30%;
      }
      .footer-column-2 {
        width: 25%;
      }
      .footer-column-3 {
        width: 28%;
      }
    }
  }

  @media only screen and (max-width: 867px) {
    .footer {
      gap: 4rem;
      .footer-column-1 {
        width: 35%;
      }
      .footer-column-2 {
        width: 35%;
      }
      .footer-column-3 {
        width: 30%;
      }
    }
  }

  @media only screen and (max-width: 650px) {
    .footer {
      flex-direction: column;
      gap: 2rem;
      padding: 1rem 1.5rem 3.5rem 1.5rem;
      .footer-column-1 {
        width: 100%;
      }
      .footer-column-2 {
        width: 100%;
        .footer-links {
          display: flex;
          flex-direction: column;
          flex-wrap: wrap;
          height: 150px;
          h5 {
          }
        }
      }
      .footer-column-3 {
        width: 100%;
      }
      .footer-column-4 {
        bottom: 0rem;
        width: 75%;
        display: flex;
        justify-content: center;
        a {
          img {
            width: 1.5rem;
          }
        }
      }
    }
    .footer-copyRight {
      padding: 0 0.5rem;
      margin-bottom: ${(props) => (props.show ? "1rem" : "0rem")};

      .footer-left {
        h5 {
          font-size: 0.5rem;
        }
      }
      .footer-right {
        h5 {
          font-size: 0.4rem;
        }
      }
    }
  }
`;

import React from "react";
import { GOCCardConatinerStyle } from "./goccard.style";

const GOCCard = ({ setGocPopUp }) => {
  return (
    <GOCCardConatinerStyle
      onMouseLeave={() => {
        setGocPopUp(false);
      }}
      initial={{ opacity: 0 }}
      whileInView={{ opacity: 1 }}
      transition={{ duration: 0.5 }}
    >
      <a
        href="https://www.prglobalresources.com/"
        target="_blank"
        rel="noreferrer"
      >
        PR Agriculture Export India
      </a>
      <a href="https://prpaperindustries.com/" target="_blank" rel="noreferrer">
        PR Global Resources India
      </a>
    </GOCCardConatinerStyle>
  );
};

export default GOCCard;

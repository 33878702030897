import styled from "styled-components";

export const FileViewerStyle = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.4);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  z-index: 999999;

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
`;

/** @format */

import React, { useEffect } from "react";
import { Wrapper } from "./AboutPage.style";
import firstImg from "../../assets/aboutPage-1.png";
import secondImg from "../../assets/aboutPage-2.png";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper";
import stat from "../../assets/stationary 1.png";
import doc from "../../assets/documents 1.png";
import flask from "../../assets/flask 1.png";
import chem from "../../assets/chemicals 1.png";
import construction from "../../assets/construction 1.png";
import pack from "../../assets/package 1.png";
import cube from "../../assets/cubes 1.png";
import target from "../../assets/target 1.png";
import earth from "../../assets/pin 1.png";
import hand from "../../assets/review 1.png";

import { motion } from "framer-motion";

const AboutPage = () => {
  useEffect(() => {
    window.scrollTo(0, document.body.scrollTop);
  }, []);

  return (
    <Wrapper>
      <div className="about-page">
        <div className="first-section">
          <div className="first-section-row-1">
            <div className="first-section-title">
              <motion.h3
                initial={{ x: -200, opacity: 0 }}
                whileInView={{ x: 0, opacity: 1 }}
                viewport={{ once: true }}
                transition={{ duration: 0.75, ease: "easeOut" }}
              >
                About Us
              </motion.h3>
            </div>
            <div className="first-section-row-1-center">
              <motion.p
                initial={{ y: 200, opacity: 0 }}
                whileInView={{ y: 0, opacity: 1 }}
                viewport={{ once: true }}
                transition={{ duration: 0.75, ease: "easeOut" }}
              >
                <span className="first-span">
                  P.R. GLOBAL RESOURCES INDIA PRIVATE LIMITED (PRGRIPL)
                </span>{" "}
                are a Group Company of{" "}
                <span className="second-span">
                  P.R. GLOBAL RESOURCES INDIA (PRGRI)
                </span>{" "}
                Is a Leading Exporter of all types of Indian Origin Papers,
                Paper Boards, Specialty Grade Coated/Un Coated Papers and Paper
                Boards, Printing, Publishing and Packaging Materials.
              </motion.p>
            </div>
          </div>
          <div className="first-section-row-2">
            <motion.div
              initial={{ opacity: 0 }}
              whileInView={{ opacity: 1 }}
              viewport={{ once: true }}
              transition={{ duration: 0.75 }}
              className="first-section-row-2-left"
            >
              <img src={secondImg} alt="" />
            </motion.div>
            <motion.div
              initial={{ opacity: 0 }}
              whileInView={{ opacity: 1 }}
              transition={{ duration: 0.75 }}
              viewport={{ once: true }}
              className="first-section-row-2-right"
            >
              <p>
                We are a trusted and well-known Paper Brand in the global
                market. Our Registered Office is located from close to Mumbai
                Ports, in Nagpur city (Maharashtra State), Central India. Please
                find herewith a list of products, we export from India are: -
                (for more details, please refer the product profile Section
                herewith)
              </p>
            </motion.div>
          </div>
          <div className="first-section-row-3">
            <motion.div
              initial={{ opacity: 0 }}
              whileInView={{ opacity: 1 }}
              transition={{ duration: 0.75 }}
              viewport={{ once: true }}
              className="first-section-row-3-left"
            >
              <p>
                Company Registered Office Situated at Central India ( Zero Miles
                ) 2.5 K.M From Dr Baba Saheb Ambedkar International Air Port, In
                Nagpur City, Maharashtra State, India from where having the
                Excellent Connectivity Of logistics by Road/Rail/Air/*Sea.
              </p>
            </motion.div>
            <motion.div
              initial={{ opacity: 0 }}
              whileInView={{ opacity: 1 }}
              transition={{ duration: 0.75 }}
              viewport={{ once: true }}
              className="first-section-row-3-right"
            >
              <img src={firstImg} alt="" />
            </motion.div>
          </div>
        </div>
        <div className="second-section">
          <motion.div
            initial={{ x: -200, opacity: 0 }}
            whileInView={{ x: 0, opacity: 1 }}
            transition={{ duration: 0.75 }}
            viewport={{ once: true }}
            className="second-section-title"
          >
            <h3>PAPERS AND PAPER BOARDS:</h3>
          </motion.div>
          <div className="second-section-body">
            <motion.p
              initial={{ x: -200, opacity: 0 }}
              whileInView={{ x: 0, opacity: 1 }}
              viewport={{ once: true }}
              transition={{ duration: 0.75, delay: 0.2 }}
            >
              ❖ MG and MF Kraft Paper /Fluting Medium/Flute Liner/Test Liner,
              Kraft Liner Boards & Semi Kraft Paper.
            </motion.p>
            <motion.p
              initial={{ x: -200, opacity: 0 }}
              whileInView={{ x: 0, opacity: 1 }}
              viewport={{ once: true }}
              transition={{ duration: 0.75, delay: 0.4 }}
            >
              ❖ Core Board Kraft Paper, Paper Tube Core Board Kraft Paper, Grey
              Board Kraft Paper.
            </motion.p>
            <motion.p
              initial={{ x: -200, opacity: 0 }}
              whileInView={{ x: 0, opacity: 1 }}
              viewport={{ once: true }}
              transition={{ duration: 0.75, delay: 0.6 }}
            >
              ❖ Speciality Grade Kraft Paper, Sack Kraft Paper, Bleached Kraft,
              UN Bleached Kraft, Colored Kraft Paper, White Top Test Liner Kraft
              Paper, Coated and Un Coated Kraft Paper.
            </motion.p>
            <motion.p
              initial={{ x: -200, opacity: 0 }}
              whileInView={{ x: 0, opacity: 1 }}
              viewport={{ once: true }}
              transition={{ duration: 0.75, delay: 0.8 }}
            >
              ❖ Duplex Board Paper, LWC-Grey Back, HWC-Grey Back, HWC-White
              Back, Coated/Un Coated Duplex Board Paper, FBB Board Laminated
              Boards Paper.
            </motion.p>
            <motion.p
              initial={{ x: -200, opacity: 0 }}
              whileInView={{ x: 0, opacity: 1 }}
              viewport={{ once: true }}
              transition={{ duration: 0.75, delay: 1 }}
            >
              ❖ Wood Free Paper, Offset Paper, Writing and Printing Paper, Map
              litho Paper, Color Offset Paper, Note Book Paper, Newsprint Paper,
              Azure laid Paper, Copier Paper.
            </motion.p>
            <motion.p
              initial={{ x: -200, opacity: 0 }}
              whileInView={{ x: 0, opacity: 1 }}
              viewport={{ once: true }}
              transition={{ duration: 0.75, delay: 1.2 }}
            >
              ❖ Coated Papers, Wax Coated Paper, Oil and Grease Resistant Paper,
              Water Resistant Paper, Heat Resistant Paper, Sandwich Paper, Foil
              Laminated Paper,
            </motion.p>
            <motion.p
              initial={{ x: -200, opacity: 0 }}
              whileInView={{ x: 0, opacity: 1 }}
              viewport={{ once: true }}
              transition={{ duration: 0.75, delay: 1.4 }}
            >
              ❖ PE Coated Papers and Boards.
            </motion.p>
            <motion.p
              initial={{ x: -200, opacity: 0 }}
              whileInView={{ x: 0, opacity: 1 }}
              viewport={{ once: true }}
              transition={{ duration: 0.75, delay: 1.6 }}
            >
              ❖ Art Paper, Art Card, Chrome Paper, Poster Paper, MG Poster
              Paper, PE Coated Cup Board Paper, Disposable Paper Cup Board, PE
              Coated Stiffener Paper.
            </motion.p>
            <motion.p
              initial={{ x: -200, opacity: 0 }}
              whileInView={{ x: 0, opacity: 1 }}
              viewport={{ once: true }}
              transition={{ duration: 0.75, delay: 1.8 }}
            >
              ❖ Office Stationeries, College Stationeries, Universities
              Stationers, Note Book /Exercise Note Book, Counter Books & Other
              Stationeries Materials.
            </motion.p>
            <motion.p
              initial={{ x: -200, opacity: 0 }}
              whileInView={{ x: 0, opacity: 1 }}
              viewport={{ once: true }}
              transition={{ duration: 0.75, delay: 2 }}
            >
              ❖ Heavy Duty Industrial Corrugated Boxes, Mono Cartoon, FMCG
              Packaging Material, Pharmaceutical Packaging Boxes, Automobile &
              Electrical Packaging Boxes and Materials.
            </motion.p>
            <motion.p
              initial={{ x: -200, opacity: 0 }}
              whileInView={{ x: 0, opacity: 1 }}
              viewport={{ once: true }}
              transition={{ duration: 0.75, delay: 2.2 }}
            >
              ❖ Waste Paper Raw Material-Indian and Imported Waste Paper.
            </motion.p>
          </div>
        </div>
        <div className="third-section">
          <motion.div
            initial={{ x: -200, opacity: 0 }}
            whileInView={{ x: 0, opacity: 1 }}
            transition={{ duration: 0.75 }}
            viewport={{ once: true }}
            className="third-section-title"
          >
            <h3>We also deals in Exports of below mentioned items:</h3>
          </motion.div>
          <div className="third-section-body">
            <motion.p
              initial={{ x: -200, opacity: 0 }}
              whileInView={{ x: 0, opacity: 1 }}
              viewport={{ once: true }}
              transition={{ duration: 0.75, delay: 0.2 }}
            >
              ➢ All Types of Poly and Polypropylenes Materials.
            </motion.p>
            <motion.p
              initial={{ x: -200, opacity: 0 }}
              whileInView={{ x: 0, opacity: 1 }}
              viewport={{ once: true }}
              transition={{ duration: 0.75, delay: 0.4 }}
            >
              ➢ All Types of Aluminums, Foils, Angle, Channels.
            </motion.p>
            <motion.p
              initial={{ x: -200, opacity: 0 }}
              whileInView={{ x: 0, opacity: 1 }}
              viewport={{ once: true }}
              transition={{ duration: 0.75, delay: 0.6 }}
            >
              ➢ All Types of Steels, Metals, TMT’s, Angle, Gutters, Heavy Duty
              Costing Materials.
            </motion.p>
            <motion.p
              initial={{ x: -200, opacity: 0 }}
              whileInView={{ x: 0, opacity: 1 }}
              viewport={{ once: true }}
              transition={{ duration: 0.75, delay: 0.8 }}
            >
              ➢ All Types of Agriculture Products, Spices, Rice, Maze Corn,
              Fresh Fruits, Vegetables, Cereals, Pulps.
            </motion.p>
            <motion.p
              initial={{ x: -200, opacity: 0 }}
              whileInView={{ x: 0, opacity: 1 }}
              viewport={{ once: true }}
              transition={{ duration: 0.75, delay: 1 }}
            >
              ➢ All Types of Chemicals Used In Paper Mills and In Printing and
              Packaging Industries.
            </motion.p>
          </div>
        </div>
        <div className="fourth-section">
          <div className="overlay"></div>
          <div className="fourth-section-container">
            <div className="fourth-section-container-p">
              <motion.p
                initial={{ scale: 0, opacity: 0 }}
                whileInView={{ scale: 1, opacity: 1 }}
                transition={{ duration: 0.75 }}
                viewport={{ once: true }}
              >
                WE BELIEVE IN GREAT IDEAS AND INVENTION TO EXPLORE INTERNATIONAL
                MARKET’S i.e NEW TECHNIQUES, UPDATED IDEA’S, UPDATED INVENTION
                AS PER MARKET NEED , LATEST BRANDING ,ASSEMBLING AND CUSTOMIZING
                METHODOLOGY TO SERVE THE ALL PRODUCT’S & SERVICES UNDER ONE ROOF
                FACILITY SYSTEMS, LATEST MARKETING STRATEGIES WITH CUSTOMER
                SATISFACTION ETC.
              </motion.p>
            </div>
            <div className="fourth-section-container-h">
              <motion.h3
                initial={{ opacity: 0 }}
                whileInView={{ opacity: 1 }}
                viewport={{ once: true }}
                transition={{ duration: 0.75, delay: 0.3 }}
              >
                “A Good Deed Always Comes Around”
              </motion.h3>
            </div>
          </div>
        </div>
        <div className="fifth-section">
          <div className="fifth-section-title">
            <motion.h2
              initial={{ x: -200, opacity: 0 }}
              whileInView={{ x: 0, opacity: 1 }}
              viewport={{ once: true }}
              transition={{ duration: 0.75 }}
            >
              OUR PRODUCTS
            </motion.h2>
            <motion.h3
              initial={{ x: -200, opacity: 0 }}
              whileInView={{ x: 0, opacity: 1 }}
              viewport={{ once: true }}
              transition={{ duration: 0.75, delay: 0.3 }}
            >
              Some of our product categories in which we provide our Import
              Export Services
            </motion.h3>
          </div>
          <div className="fifth-section-container">
            <Swiper
              slidesPerView={3}
              spaceBetween={30}
              style={{ padding: "4rem 0rem" }}
              autoplay={{
                delay: 2500,
                disableOnInteraction: false,
              }}
              modules={[Autoplay]}
              breakpoints={{
                375: {
                  slidesPerView: 1,
                  spaceBetween: 20,
                },
                1024: {
                  slidesPerView: 3,
                  spaceBetween: 30,
                },
                999: {
                  slidesPerView: 3,
                  spaceBetween: 20,
                },
                280: {
                  slidesPerView: 1,
                  spaceBetween: 30,
                },
              }}
            >
              <SwiperSlide>
                <div className="service-card">
                  <div className="service-circle">
                    <img src={stat} alt="user agent" />
                  </div>
                  <h3>Commissioning/Agent</h3>
                  <p>
                    The Company acting as a Supporting Manufacturer by giving
                    there orders to the different Manufacturers of Specialty
                    Grade Papers and Paper Boards and other profile Products as
                    per the Requirement of Indian and Overseas Customers
                  </p>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="service-card">
                  <div className="service-circle">
                    <img src={doc} alt="cargo ship" />
                  </div>
                  <h3>Merchant Exporter</h3>
                  <p>
                    The Company acting as a Supporting Manufacturer by giving
                    there orders to the different Manufacturers of Specialty
                    Grade Papers and Paper Boards and other profile Products as
                    per the Requirement of Indian and Overseas Customers
                  </p>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="service-card">
                  <div className="service-circle">
                    <img src={flask} alt="factory" />
                  </div>
                  <h3>Supporting Manufacturer</h3>
                  <p>
                    The Company acting as a Supporting Manufacturer by giving
                    there orders to the diffrent diffrent Manufacturers of
                    Speciallity Garade Papers and Paper Boards and other profile
                    Products as per the Requirement of Indian and Overseas
                    Customers.
                  </p>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="service-card">
                  <div className="service-circle">
                    <img src={chem} alt="user agent" />
                  </div>
                  <h3>Commissioning/Agent</h3>
                  <p>
                    The Company acting as a Supporting Manufacturer by giving
                    there orders to the different Manufacturers of Specialty
                    Grade Papers and Paper Boards and other profile Products as
                    per the Requirement of Indian and Overseas Customers
                  </p>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="service-card">
                  <div className="service-circle">
                    <img src={construction} alt="cargo ship" />
                  </div>
                  <h3>Merchant Exporter</h3>
                  <p>
                    The Company acting as a Supporting Manufacturer by giving
                    there orders to the different Manufacturers of Specialty
                    Grade Papers and Paper Boards and other profile Products as
                    per the Requirement of Indian and Overseas Customers
                  </p>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="service-card">
                  <div className="service-circle">
                    <img src={pack} alt="factory" />
                  </div>
                  <h3>Supporting Manufacturer</h3>
                  <p>
                    The Company acting as a Supporting Manufacturer by giving
                    there orders to the diffrent diffrent Manufacturers of
                    Speciallity Garade Papers and Paper Boards and other profile
                    Products as per the Requirement of Indian and Overseas
                    Customers.
                  </p>
                </div>
              </SwiperSlide>
            </Swiper>
          </div>
        </div>
        <div className="sixth-section">
          <div className="sixth-section-container">
            <div className="sixth-section-title">
              <motion.h3
                initial={{ x: -200, opacity: 0 }}
                whileInView={{ x: 0, opacity: 1 }}
                transition={{ duration: 0.75 }}
                viewport={{ once: true }}
              >
                FUN FACTS ABOUT US
              </motion.h3>
            </div>
            <div className="sixth-section-body">
              <motion.p
                initial={{ x: -200, opacity: 0 }}
                whileInView={{ x: 0, opacity: 1 }}
                transition={{ duration: 0.75, delay: 0.3 }}
                viewport={{ once: true }}
              >
                Fun fact is a tidbit of interesting or entertaining trivia.
                People often introduce a fun fact, sometimes in the form of
                witty and biting observations, with the phrase itself because
                otherwise how would you know it was a fun fact.
              </motion.p>
            </div>
          </div>
        </div>
        <div className="seventh-section">
          <motion.div
            initial={{ scale: 0, opacity: 0 }}
            whileInView={{ scale: 1, opacity: 1 }}
            viewport={{ once: true }}
            transition={{ duration: 0.75, delay: 0.4 }}
            className="seventh-section-col-1"
          >
            <div className="img-background">
              <img src={cube} alt="" />
            </div>
            <h3>1000</h3>
            <h5>Products</h5>
          </motion.div>
          <motion.div
            initial={{ scale: 0, opacity: 0 }}
            whileInView={{ scale: 1, opacity: 1 }}
            transition={{ duration: 0.75, delay: 0.6 }}
            viewport={{ once: true }}
            className="seventh-section-col-2"
          >
            <div className="img-background">
              <img src={target} alt="" />
            </div>
            <h3>100000</h3>
            <h5>Clients</h5>
          </motion.div>
          <motion.div
            initial={{ scale: 0, opacity: 0 }}
            whileInView={{ scale: 1, opacity: 1 }}
            transition={{ duration: 0.75, delay: 0.8 }}
            viewport={{ once: true }}
            className="seventh-section-col-3"
          >
            <div className="img-background">
              <img src={earth} alt="" />
            </div>
            <h3>32</h3>
            <h5>No. of Country</h5>
          </motion.div>
          <motion.div
            initial={{ scale: 0, opacity: 0 }}
            whileInView={{ scale: 1, opacity: 1 }}
            transition={{ duration: 0.75, delay: 1 }}
            viewport={{ once: true }}
            className="seventh-section-col-4"
          >
            <div className="img-background">
              <img src={hand} alt="" />
            </div>
            <h3>80</h3>
            <h5>% Happy Clients</h5>
          </motion.div>
        </div>
      </div>
    </Wrapper>
  );
};

export default AboutPage;

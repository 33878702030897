/** @format */

import { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import { Wrapper } from "../Modal/modal.style";
import CloseIconImg from "../../assets/close-icon.png";
import { useMainContext } from "../../context/context";
import { toast } from "react-hot-toast";
import { postEndpoints } from "../../utils/Constants";
import axios from "axios";
// import OtpModal from "../OtpModal/OtpModal";
import { IMG_URL } from "../../utils/Constants";

const Modal = ({ product }) => {
  // console.log(product);

  const contactObj = {
    name: "",
    email: "",
    mobile_no: "",
    location: "",
    company_name: "",
    website: "",
    min_quantity: "",
  };

  const {
    setShowModal,
    id,
    // showOtpModal,
    setShowOtpModal,
    // setOtpLoading,
    // otpLoading,
    // data,
    setData,
    // seconds,
    // startTimer,
    // resend,
    // resendFucn,
  } = useMainContext();
  // console.log(id);

  const [formData, setFormData] = useState(contactObj);
  const [loading, setLoading] = useState(false);

  const {
    name,
    email,
    mobile_no,
    location,
    company_name,
    website,
    min_quantity,
  } = formData;

  const [isSelected, setIsSelected] = useState();
  const [user, setUser] = useState();

  const typeOfUser = [
    "Sole Proprietor",
    "Company",
    "Agent/Broker",
    "Dealer",
    "Other",
  ];

  // const clearFormFields = () => {
  //   setFormData((prevState) => ({
  //     ...prevState,
  //     name: "",
  //     email: "",
  //     mobile_no: "",
  //     location: ""
  //     // product_id: "",
  //     // mobile_OTP: ""
  //   }));
  // };

  // console.log(seconds)

  const validateInputs = () => {
    let validateName = /^[A-Za-z.-]+(\s*[A-Za-z.-]+)*$/;
    let validateEmail = /[^@ \t\r\n]+@[^@ \t\r\n]+\.[^@ \t\r\n]+/;
    let validateMobNo =
      /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/;

    if (!validateName.test(name)) {
      setLoading(false);
      toast.error("Please enter valid name");
      return false;
    }

    if (!validateEmail.test(email)) {
      setLoading(false);
      toast.error("Please enter valid email");
      return false;
    }

    if (!validateMobNo.test(mobile_no)) {
      setLoading(false);
      toast.error("Please enter valid mobile no");
      return false;
    }

    if (min_quantity === "") {
      setLoading(false);
      toast.error("Please enter minimum quantity");
      return false;
    }

    return true;
  };

  useEffect(() => {
    document.body.style.overflowY = "hidden";
    window.scrollTo(0, document.body.scrollTop);
    return () => {
      document.body.style.overflowY = "scroll";
    };
  }, []);

  const handleOnSubmit = async (event) => {
    // console.log("CLicked");

    setLoading(true);
    // startTimer();

    const payLoad = {
      name,
      email,
      mobile_no,
      location,
      product_id: id,
      user_type: user,
      company_name,
      website,
      min_quantity,
    };

    try {
      if (validateInputs()) {
        const response = await axios.post(postEndpoints.postEnquiry, payLoad);

        setData(response.data.data);
        // console.log(response.data)

        if (response.data.status === "success") {
          toast.success(response.data.message);
          setLoading(false);
          setShowModal(false);
          // setShowOtpModal(true);
        } else if (response.data.status === "failed") {
          toast.error(response.data.message);
          setLoading(false);
          setShowOtpModal(false);
          setShowModal(false);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleOnChange = (e) => {
    setFormData((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  // const dataFetchModal = (data) => {
  //   setShowModal(data);
  // };
  // const dataFetchOtpModal = (data) => {
  //   setShowOtpModal(data);
  // };

  const handleTypeOfUser = (data, index) => {
    setIsSelected(index);
    setUser(data);
  };

  return ReactDOM.createPortal(
    <>
      <Wrapper>
        <div
          className="modal-wrapper"
          onClick={() => setShowModal(false)}
        ></div>

        <div className="modal">
          <div className="modal-header">
            <div className="modal-title">
              <h3>Please Share Your Contact</h3>
            </div>
            <div className="modal-close" onClick={() => setShowModal(false)}>
              <img src={CloseIconImg} alt="" />
            </div>
          </div>
          <div className="modal-body">
            <div className="modal-left">
              <div className="modal-img">
                <img src={IMG_URL + product?.images[0]} alt="" />
              </div>
              <div className="modal-name">
                <h3>{product?.product_name}</h3>
              </div>
              <div className="modal-price-rating">
                <div className="modal-price">
                  <h3>{`₹ ${product?.product_price}`}</h3>
                </div>
                <div className="modal-rating">
                  <h5>⭐ {product?.product_avg_rate} Ratings </h5>
                </div>
              </div>
            </div>

            <div className="modal-right">
              <div className="modal-info">
                <div className="modal-info-row-1">
                  <input
                    type="text"
                    placeholder="Name"
                    onChange={handleOnChange}
                    name="name"
                    value={name}
                    required
                  />
                  <input
                    type="text"
                    placeholder="Phone Number"
                    onChange={handleOnChange}
                    name="mobile_no"
                    value={mobile_no}
                    required
                    maxLength={10}
                  />
                </div>
                <div className="modal-info-row-2">
                  <input
                    type="text"
                    placeholder="Email"
                    onChange={handleOnChange}
                    name="email"
                    value={email}
                    required
                  />
                  <input
                    type="text"
                    placeholder="Location"
                    value={location}
                    onChange={handleOnChange}
                    name="location"
                    required
                  />
                </div>
                <div className="modal-info-row-3">
                  <input
                    type="text"
                    placeholder="Company Name"
                    value={company_name}
                    onChange={handleOnChange}
                    name="company_name"
                  />
                  <input
                    type="text"
                    placeholder="Website"
                    value={website}
                    onChange={handleOnChange}
                    name="website"
                  />
                </div>
                <div className="modal-info-row-4">
                  <input
                    type="text"
                    placeholder="Minimum Quantity"
                    value={min_quantity}
                    onChange={handleOnChange}
                    name="min_quantity"
                  />
                </div>
              </div>
              <div className="modal-typeOfUser">
                <div className="modal-typeOfUser-title">
                  <h3>What type of user you are?</h3>
                </div>
                <div className="modal-typeOfUser-body">
                  {typeOfUser.map((curElem, index) => {
                    return (
                      <div className="modal-typeOfUser-child" key={index}>
                        <button
                          onClick={() => handleTypeOfUser(curElem, index)}
                          style={
                            isSelected === index
                              ? { backgroundColor: "#0099FF" }
                              : { backgroundColor: "#FFFFFF" }
                          }
                        >
                          {curElem}
                        </button>
                      </div>
                    );
                  })}
                </div>
              </div>
              <div className="modal-button">
                <button onClick={handleOnSubmit}>
                  {loading ? "Submitting..." : "Get best Price"}
                </button>
              </div>
            </div>
          </div>
        </div>
        {/* {showOtpModal && (
          <OtpModal
            name={name}
            email={email}
            mobile_no={mobile_no}
            product_id={id}
            data={data}
            sendModalData={dataFetchModal}
            sendOtpModalData={dataFetchOtpModal}
            otpLoading={otpLoading}
            setOtpLoading={setOtpLoading}
            seconds={seconds}
            setShowOtpModal={setShowOtpModal}
            resend={resend}
            startTimer={startTimer}
            resendFucn={resendFucn}
            setLoading={setLoading}
          />
        )} */}
      </Wrapper>
    </>,
    document.querySelector(".myPortalModalDiv")
  );
};

export default Modal;

/** @format */

import React from "react";
import { Link } from "react-router-dom";

import { HoverCardContainer } from "./hovercard.style";

import { IoIosArrowForward } from "react-icons/io";

import { fetchCategoriesSubCategory } from "../../utils/APIUrls";
import { useQuery } from "@tanstack/react-query";

const HoverCard = ({ setShowPopUp }) => {
  const { data: categoriesSubCategory } = useQuery({
    queryKey: ["categoriessubcategory"],
    queryFn: () => fetchCategoriesSubCategory(),
  });

  return (
    <HoverCardContainer
      onMouseLeave={() => {
        setShowPopUp(false);
      }}
      initial={{ opacity: 0 }}
      whileInView={{ opacity: 1 }}
      transition={{ duration: 0.5 }}
    >
      <div className="categories-wrapper">
        {categoriesSubCategory?.data?.map((catsub) => (
          <div className="category" key={catsub?.category?._id}>
            <h1>{catsub?.category?.title}</h1>
            <div className="sub-category">
              {catsub?.subcategory?.map((subcategory) => (
                <Link
                  key={subcategory?._id}
                  to={`/products/${subcategory?._id}`}
                >
                  <div className="links-wrapper">
                    <span>
                      <IoIosArrowForward color="#717171" size={8} />
                    </span>

                    <h5 onClick={() => setShowPopUp(false)}>
                      {subcategory?.title}
                    </h5>
                  </div>
                </Link>
              ))}
            </div>
          </div>
        ))}
      </div>
    </HoverCardContainer>
  );
};

export default HoverCard;

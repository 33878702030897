import React, { useState } from "react";
import { Link } from "react-router-dom";
import { IoIosArrowUp, IoIosArrowDown } from "react-icons/io";
import { NavLinkStyle } from "./togglenav.style";

const ToggleNav = ({ children, linkTitle, pathname }) => {
  const [toggle, setToggle] = useState(false);
  const [activeNav, setActiveNav] = useState(false);
  return (
    <div onClick={() => setActiveNav((prevState) => !prevState)}>
      <NavLinkStyle
        activeNav={activeNav}
        onClick={() => setToggle((prevState) => !prevState)}
      >
        <strong>
          <Link to={pathname} className={activeNav ? "active" : ""}>
            {linkTitle}
          </Link>
        </strong>
        {toggle ? (
          <IoIosArrowUp size={20} color="#000000" />
        ) : (
          <IoIosArrowDown size={20} color="#000000" />
        )}
      </NavLinkStyle>

      {toggle && children}
    </div>
  );
};

export default ToggleNav;

/** @format */

import queryString from "query-string";
import "rc-slider/assets/index.css";
import Slider from "rc-slider/lib/";
import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { getEndpoints, postEndpoints } from "../../utils/Constants";
import { PRFilterContainerStyle, ProductsSecStyle } from "./products.style";

import { useQuery } from "@tanstack/react-query";
import { FiSearch } from "react-icons/fi";
import {
  MdOutlineCheckBox,
  MdOutlineCheckBoxOutlineBlank,
} from "react-icons/md";
import { fetchCategories, fetchSubCategories } from "../../utils/APIUrls";

import axios from "axios";
import { Card, MobileFIlter, Modal } from "../../components";
import { useMainContext } from "../../context/context";

const Products = () => {
  const [searchText, setSearchText] = useState("");
  const { setShowModal, showModal } = useMainContext();
  const [productData, setProductData] = useState({});

  const [pageNo, setPageNo] = useState(1);
  const [hasNextPage, setHasNextPage] = useState(false);
  const [loadMoreLoading, setLoadMoreLoading] = useState(false);

  const [productList, setProductList] = useState([]);

  let location = queryString.parse(window.location.search);

  const navigate = useNavigate();

  const { subid } = useParams();

  const { data: categories } = useQuery({
    queryKey: ["categories"],
    queryFn: () => fetchCategories(),
  });

  const { data: subcategories } = useQuery({
    queryKey: ["subcategories"],
    queryFn: () => fetchSubCategories(),
  });

  const fetchAllProducts = async () => {
    const apiURL = queryString.stringify(location);
    setLoadMoreLoading(true);

    try {
      const response = await axios.get(
        `${getEndpoints.getAllProducts}?subCatId=${subid}&page=${pageNo}&${apiURL}`
      );

      setHasNextPage(response.data.data.hasNextPage);

      if (response.data.status === "success") {
        if (pageNo === 1) {
          setProductList([...response.data.data.docs]);
        } else {
          setProductList((prevState) => [
            ...prevState,
            ...response.data.data.docs,
          ]);
        }

        setLoadMoreLoading(false);
      }
    } catch (error) {
      console.log(error);
    }
  };

  // Category Filter
  const handleIsCatChecked = (catid) => {
    if (location["catId[]"]) {
      if (typeof location["catId[]"] === "string") {
        return location["catId[]"] === catid;
      } else {
        return location["catId[]"].includes(catid);
      }
    }
  };

  const handleCategoryFilter = (id) => {
    const prevURL = queryString.stringify(location);

    if (!location["catId[]"]) {
      location["catId[]"] = id;
      navigate("?" + prevURL + "&catId[]=" + id);
    } else {
      if (typeof location["catId[]"] === "string") {
        if (location["catId[]"] === id) {
          delete location["catId[]"];
        } else {
          location["catId[]"] = [location["catId[]"], id];
        }
      } else {
        const isCat = location["catId[]"].find((cat) => cat === id);
        if (isCat) {
          const idx = location["catId[]"].indexOf(id);
          location["catId[]"].splice(idx, 1);
        } else {
          location["catId[]"].push(id);
        }
      }
    }

    const catString = queryString.stringify(location);
    navigate("?" + catString);

    setPageNo(1);

    fetchAllProducts(location);
  };

  // Sub CategoryFilter
  const handleIsSubCatChecked = (catid) => {
    if (location["subCatId[]"]) {
      if (typeof location["subCatId[]"] === "string") {
        return location["subCatId[]"] === catid;
      } else {
        return location["subCatId[]"].includes(catid);
      }
    }
  };

  const handleSubCategoryFilter = (id) => {
    const prevURL = queryString.stringify(location);

    if (!location["subCatId[]"]) {
      location["subCatId[]"] = id;
      navigate("?" + prevURL + "&subCatId[]=" + id);
    } else {
      if (typeof location["subCatId[]"] === "string") {
        if (location["subCatId[]"] === id) {
          delete location["subCatId[]"];
        } else {
          location["subCatId[]"] = [location["subCatId[]"], id];
        }
      } else {
        const isCat = location["subCatId[]"].find((cat) => cat === id);
        if (isCat) {
          const idx = location["subCatId[]"].indexOf(id);
          location["subCatId[]"].splice(idx, 1);
        } else {
          location["subCatId[]"].push(id);
        }
      }
    }

    const subCatString = queryString.stringify(location);
    navigate("?" + subCatString);

    setPageNo(1);

    fetchAllProducts(location);
  };

  const handleSliderChange = (event) => {
    if (event) {
      location["price_min"] = event[0];
      location["price_max"] = event[1];
      let prevUrl = queryString.stringify(location);
      navigate("?" + prevUrl);

      setPageNo(1);

      fetchAllProducts(location);
    }
  };

  const handleSearchChange = async (e) => {
    if (e.target.value.length >= 0) {
      setSearchText(e.target.value);
    }

    if (e.target.value.length === 0) {
      window.location.reload();
    }

    try {
      const response = await axios.post(
        postEndpoints.postSearch + `?find=${e.target.value}`
      );
      if (response.data.status === "success") {
        setProductList(response.data.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const marks = {
    // 0: location["price_min"] ? "₹" + location["price_min"] : "₹0",
    // 200000: location["price_max"] ? "₹" + location["price_max"] : "₹200000",
    1000: 1000,
    20000: 20000,
    60000: 60000,
    100000: 100000,
  };

  useEffect(() => {
    fetchAllProducts();
    // window.scrollTo(0, 0);
  }, [subid, pageNo]);

  return (
    <ProductsSecStyle>
      {window.innerWidth < 768 && (
        <MobileFIlter setProductList={setProductList} />
      )}
      <div className="navigation-products">
        <h1>
          <span>
            <Link to="/">Home </Link>
          </span>
          /
          <span onClick={() => fetchAllProducts()}>
            <Link to="/allproducts">Product Listing</Link>
          </span>
          /
          <span>
            <Link to="/allproducts">Paper</Link>
          </span>
        </h1>
      </div>

      <PRFilterContainerStyle>
        <div className="category-filter">
          <h1>Filters</h1>

          <div className="filter-divider"></div>

          <div className="categories-container">
            <h2>Categories</h2>

            <div className="category-wrapper">
              {categories?.data?.docs?.map((category) => (
                <div
                  className="category-content"
                  key={category._id}
                  onClick={() => handleCategoryFilter(category?._id)}
                >
                  {handleIsCatChecked(category?._id) ? (
                    <MdOutlineCheckBox color="#787878" size={20} />
                  ) : (
                    <MdOutlineCheckBoxOutlineBlank color="#787878" size={20} />
                  )}

                  <h3>{category.title}</h3>
                </div>
              ))}
            </div>
          </div>

          <div className="filter-divider"></div>

          <div className="sub-categories-container">
            <h2>Sub Categories</h2>
            <div className="sub-category-wrapper">
              {subcategories?.data?.docs?.map((subcat) => (
                <div
                  className="sub-cat-content"
                  key={subcat?._id}
                  onClick={() => handleSubCategoryFilter(subcat?._id)}
                >
                  {handleIsSubCatChecked(subcat?._id) ? (
                    <MdOutlineCheckBox color="#787878" size={20} />
                  ) : (
                    <MdOutlineCheckBoxOutlineBlank color="#787878" size={20} />
                  )}
                  <h3>{subcat?.title}</h3>
                </div>
              ))}
            </div>
          </div>

          {/* <div className="filter-divider"></div> */}

          {/* <div className="price-container">
            <h2>Price</h2>

            <div className="price-content-wrapper">
              <Slider
                range
                min={1000}
                max={100000}
                marks={marks}
                step={null}
                defaultValue={[1000, 100000]}
                onChange={handleSliderChange}
              />
            </div>
          </div> */}
        </div>

        <div className="products-list-container">
          <div className="search-sort-container">
            <div className="search-input">
              <input
                type="search"
                name="search"
                value={searchText}
                onChange={handleSearchChange}
                placeholder="Search for product"
              />

              <FiSearch size={25} className="search-icon" />
            </div>

            {/* <div className="sort-filter">
              <div className="sort-by">
                <div className="sort-text">
                  <span>Sort by :</span>
                  <h4>Recommended</h4>
                </div>
                <FaChevronDown size={20} className="chevron-down" />
              </div>
            </div> */}
          </div>

          {/* Product list */}
          <div className="products-array">
            {productList?.map((product) => (
              <div onClick={() => setProductData(product)}>
                <Card product={product} key={product?._id} />
              </div>
            ))}
          </div>
          {hasNextPage && (
            <div className="load-more-btn">
              <button onClick={() => setPageNo((prevState) => prevState + 1)}>
                {loadMoreLoading ? "Loading..." : "Load More Papers"}
              </button>
            </div>
          )}
        </div>
      </PRFilterContainerStyle>
      {showModal && <Modal product={productData} />}
    </ProductsSecStyle>
  );
};

export default Products;

import React, { useEffect, useState } from "react";
import {
  CertificateSecStyle,
  CertificateLisContainer,
} from "./certificates.style";
import FileSaver from "file-saver";

import { FileViewer } from "../../components";

import { fetchCertificates } from "../../utils/APIUrls";
import { IMG_URL } from "../../utils/Constants";
import { useQuery } from "@tanstack/react-query";

const Certificates = () => {
  const { data: certificates } = useQuery({
    queryKey: ["certificates"],
    queryFn: () => fetchCertificates(),
  });

  const [isViewOpen, setIsViewOpen] = useState(false);
  const [certificatePath, setCertificatePath] = useState("");

  useEffect(() => {
    window.scrollTo(0, 0);
  });

  return (
    <CertificateSecStyle>
      <div className="certificate-header">
        <h1>Certificates</h1>
        <p>Some certificates to display are listed here.</p>
      </div>

      <CertificateLisContainer>
        {certificates?.data?.docs.map((certificate) => (
          <div
            onClick={() => {
              setIsViewOpen(true);
              setCertificatePath(certificate?.certificate_path);
            }}
            key={certificate._id}
          >
            <img
              src={IMG_URL + certificate.certificate_path}
              alt={certificate.title}
            />
          </div>
        ))}
      </CertificateLisContainer>

      {isViewOpen && (
        <FileViewer
          setIsViewOpen={setIsViewOpen}
          certificatePath={certificatePath}
        />
      )}
    </CertificateSecStyle>
  );
};

export default Certificates;

import styled from "styled-components";
import { motion } from "framer-motion";

export const GOCCardConatinerStyle = styled(motion.div)`
  position: absolute;
  top: 3.7rem;
  right: 0rem;
  background-color: var(--white-color);
  box-shadow: 0px 4px 19px rgba(0, 0, 0, 0.2);
  padding: 0rem 2rem;
  border-radius: 0.3rem;
  z-index: 897889;
  display: flex;
  flex-direction: column;

  a {
    margin: 0.5rem 0rem 1rem 0rem;
    color: black;
    font-weight: 400;
    cursor: pointer;
    font-size: 1.1rem;
    text-decoration: none;

    &:hover {
      color: var(--blue-color);
    }
  }
`;

import { Divide as Hamburger } from "hamburger-react";
import React, { useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import PRLogoBlack from "../../assets/logo_black.png";
import PRLogoWhite from "../../assets/logo_white.png";
import { HoverCard, GOCCard } from "../../components";

import { AnimatePresence } from "framer-motion";
import { Sidebar } from "../../components";
import queryString from "query-string";

import {
  NavBarContainerStyle,
  NavLeftStyle,
  NavRightStyle,
} from "./navbar.style";

const Navbar = () => {
  const [isScrolled, setIsScrolled] = useState(false);
  const [showPopUp, setShowPopUp] = useState(false);
  const [gocPopUp, setGocPopUp] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  const { pathname } = useLocation();

  const navigate = useNavigate();

  let location = queryString.parse(window.location.search);

  const handleNavbar = () => {
    if (window.scrollY > 50) {
      setIsScrolled(true);
    } else {
      setIsScrolled(false);
    }
  };

  window.addEventListener("scroll", handleNavbar);

  return (
    <NavBarContainerStyle pathname={pathname} isScrolled={isScrolled}>
      <NavLeftStyle isScrolled={isScrolled} pathname={pathname}>
        <div className="logo">
          <Link to="/">
            <img
              src={
                isScrolled
                  ? PRLogoBlack
                  : PRLogoWhite
                  ? pathname !== "/"
                    ? PRLogoBlack
                    : PRLogoWhite
                  : ""
              }
              alt="PR Global"
            />
          </Link>
        </div>

        <ul>
          <li
            onMouseEnter={() => {
              setShowPopUp(false);
              if (gocPopUp) {
                setGocPopUp(false);
              }
            }}
          >
            <Link to="/">Home</Link>
          </li>
          <li
            onMouseEnter={() => {
              setShowPopUp(false);

              if (gocPopUp) {
                setGocPopUp(false);
              }
            }}
          >
            <Link to="/about">About Us</Link>
          </li>
          <li
            onMouseEnter={() => {
              setShowPopUp(true);
              if (gocPopUp) {
                setGocPopUp(false);
              }
            }}
            onClick={() => {
              if (location["catId[]"] || location["subCatId[]"]) {
                location = {};
                navigate("/allproducts");
                window.location.reload();
              }
            }}
          >
            <Link to="/allproducts">Our Products</Link>
          </li>
          <li
            onMouseEnter={() => {
              setShowPopUp(false);
              if (gocPopUp) {
                setGocPopUp(false);
              }
            }}
          >
            <Link to="/certificates">Certificates</Link>
          </li>
          <li
            onMouseEnter={() => {
              setShowPopUp(false);
              setGocPopUp(true);
            }}
          >
            <Link to="/">Group of Companies</Link>
            {gocPopUp && <GOCCard setGocPopUp={setGocPopUp} />}
          </li>
        </ul>
      </NavLeftStyle>
      <NavRightStyle isScrolled={isScrolled} pathname={pathname}>
        <a href="#getintouch" onClick={() => navigate("/")}>
          Contact Us
        </a>
      </NavRightStyle>

      {showPopUp && <HoverCard setShowPopUp={setShowPopUp} />}

      {window.innerWidth < 768 && (
        <div>
          <Hamburger
            color={
              isScrolled
                ? "#000000"
                : "#ffffff"
                ? pathname !== "/"
                  ? "#000000"
                  : "#ffffff"
                : ""
            }
            rounded
            hideOutline={false}
            toggled={isOpen}
            toggle={setIsOpen}
            label="Show menu"
          />
        </div>
      )}
      <AnimatePresence>
        {isOpen && <Sidebar setIsOpen={setIsOpen} />}
      </AnimatePresence>
    </NavBarContainerStyle>
  );
};

export default Navbar;

/** @format */

import styled from "styled-components";
import quoteImg from "../../assets/quote-image.png";

import { motion } from "framer-motion";

export const WrapperStyle = styled.section`
  .sticky-social-media {
    .sticky-email {
      position: fixed;
      /* top: 40%; */
      top: 265px;
      right: 0;
      display: ${(props) => (props.isScrolled ? "flex" : "none")};
      z-index: 999;
      background-color: #95defc;

      flex-direction: row;
      align-items: center;

      @media screen and (max-width: 768px) {
        display: none;
      }

      img {
        width: 2rem;
      }
      a {
        h5 {
          display: ${(props) => (props.isHoveredEmail ? "flex" : "none")};
          font-size: 0.7rem;
          margin-left: 0.2rem;
          cursor: pointer;
          color: #000000;
        }
      }
    }
    .sticky-linkedin {
      position: fixed;
      top: 300px;
      right: 0;
      display: ${(props) => (props.isScrolled ? "flex" : "none")};
      z-index: 999;
      background-color: #95defc;
      /* padding: 0.1rem 0 0.1rem 0; */
      flex-direction: row;
      align-items: center;

      @media screen and (max-width: 768px) {
        display: none;
      }

      img {
        width: 2rem;
      }
      a {
        h5 {
          display: ${(props) => (props.isHoveredLinkedin ? "flex" : "none")};
          font-size: 0.7rem;
          margin-left: 0.2rem;
          cursor: pointer;
          color: #000000;
        }
      }
    }
    .sticky-facebook {
      position: fixed;
      top: 335px;
      right: 0;
      display: ${(props) => (props.isScrolled ? "flex" : "none")};
      z-index: 999;
      background-color: #95defc;

      flex-direction: row;
      align-items: center;

      @media screen and (max-width: 768px) {
        display: none;
      }

      img {
        width: 2rem;
      }
      a {
        h5 {
          display: ${(props) => (props.isHoveredFacebook ? "flex" : "none")};
          font-size: 0.7rem;
          margin-left: 0.2rem;
          cursor: pointer;
          color: #000000;
        }
      }
    }
  }
`;

export const HeroSecStyle = styled.div`
  height: 100vh;
  /* display: flex;
  align-items: center; */
  position: relative;
  /* z-index: 3434; */
`;

export const ItroContentStyle = styled(motion.div)`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 25%;
  left: 3rem;
  z-index: 100;

  @media screen and (max-width: 768px) {
    text-align: center;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    top: 0;
    left: 0;
  }

  h1 {
    font-size: 3rem;
    color: white;
    max-width: 30rem;

    @media screen and (max-width: 768px) {
      font-size: 1.8rem;
      max-width: 100%;
    }
  }

  p {
    font-size: 1.3rem;
    color: white;
    margin-top: 2rem;
    max-width: 60rem;

    @media screen and (max-width: 768px) {
      font-size: 1rem;
      max-width: 100%;
    }
  }

  .btn-container {
    display: flex;

    a {
      text-decoration: none;
      border: none;
      font-family: inherit;
      font-weight: bold;
      font-size: 1.3rem;
      color: white;
      background-color: var(--blue-color);
      padding: 1rem 2rem;
      margin-top: 2rem;
      border-radius: 0.5rem;
    }
  }
`;

export const HeroImgStyle = styled.div`
  position: relative;

  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    z-index: 23;
  }
  img {
    width: 100%;
    height: 100vh;
    object-fit: cover;
  }
`;

export const WhoWeAreStyle = styled.div`
  padding: 2rem 3rem;
  background-color: var(--faint-blue);
  height: 100vh;

  @media screen and (max-width: 768px) {
    padding: 2rem 1rem;
    height: auto;
  }

  .who-we-are-container {
    display: flex;
    align-items: center;

    @media screen and (max-width: 768px) {
      flex-direction: column;
    }

    .left-content-text {
      flex: 1 0 50%;

      p {
        margin-top: 2rem;
        font-size: 1.3rem;
        color: var(--gray-color);
        line-height: 2rem;
        text-align: justify;

        @media screen and (max-width: 768px) {
          text-align: center;
        }

        .first-span {
          display: inline-block;
          color: red;
        }

        .second-span {
          display: inline-block;
          color: var(--blue-color);
        }

        @media screen and (max-width: 768px) {
          font-size: 1rem;
          text-align: center;
        }
      }
    }

    .right-content-img {
      flex: 1 0 50%;
      display: flex;
      justify-content: center;
      align-items: center;

      img {
        margin-top: 2rem;
        width: 25rem;
        height: auto;
        object-fit: cover;

        @media screen and (max-width: 768px) {
          width: 100%;
        }
      }
    }
  }

  h2 {
    font-size: 3rem;
    text-align: center;
  }
`;

export const OurServiceStyle = styled.div`
  padding: 2rem 3rem 3rem 3rem;
  overflow-x: hidden;
  overflow-y: hidden;
  height: 100vh;

  @media screen and (max-width: 768px) {
    padding: 2rem 1rem 3rem 1rem;
    height: auto;
  }

  h2 {
    text-align: center;
    font-size: 3rem;
    margin-top: 4rem;
  }

  .services-container {
    display: flex;
    justify-content: space-between;
    gap: 2rem;
    margin-top: 6rem;

    @media screen and (max-width: 768px) {
      flex-direction: column;
      gap: 5rem;
    }

    .service-card {
      text-align: center;
      box-shadow: 0px 0px 38px rgba(41, 171, 226, 0.07);
      border: 1px solid rgba(41, 171, 226, 0.23);
      padding: 2rem 2rem;
      border-radius: 1rem;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      position: relative;

      .service-circle {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        background-color: white;
        width: 8rem;
        height: 8rem;
        border-radius: 50%;
        border: 1px solid rgba(41, 171, 226, 0.23);
        box-shadow: 0px 0px 21px rgba(41, 171, 226, 0.16);
        padding: 1rem 2rem;
        position: absolute;
        top: -4rem;

        img {
          width: 5rem;
          height: 5rem;
          object-fit: cover;
        }
      }

      h3 {
        margin-top: 4rem;
      }

      p {
        width: 20rem;
        color: var(--gray-color);
        margin-top: 1rem;
        @media screen and (max-width: 768px) {
          width: 100%;
        }
      }
    }
  }
`;

export const QuoteSecStyle = styled.div`
  background: url(${quoteImg}) center/cover no-repeat;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50vh;
  position: relative;
  z-index: 566;
  padding: 0rem 3rem;

  @media screen and (max-width: 768px) {
    padding: 0rem 1rem;
  }

  h2 {
    color: var(--white-color);
    font-size: 3rem;
    @media screen and (max-width: 768px) {
      font-size: 1.5rem;
    }
  }

  .quote-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    z-index: -1;
  }
`;

export const GetInTouchContainerStyle = styled.div`
  padding: 5rem 0rem;

  @media screen and (max-width: 768px) {
    padding: 1rem 1rem;
  }

  .get-touch-header {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-top: 1.8rem;

    h2 {
      font-size: 3rem;
    }

    p {
      font-size: 1.3rem;
      color: var(--gray-color);
      text-align: center;
      width: 67%;
      line-height: 2rem;

      @media screen and (max-width: 768px) {
        font-size: 1rem;
        width: 100%;
      }
    }
  }

  /* get touch category container */
  .get-touch-category-container {
    display: flex;
    flex-wrap: wrap;
    gap: 2rem;
    padding: 3rem 8rem;

    @media screen and (max-width: 768px) {
      padding: 3rem 1rem;
      flex-wrap: nowrap;
      flex-direction: column;
    }

    .get-touch-card {
      border: 1px solid rgba(41, 171, 226, 0.23);
      box-shadow: 0px 0px 38px rgba(41, 171, 226, 0.07);
      border-radius: 1rem;
      padding: 1rem 1rem;
      flex: 1 0 40%;

      h2 {
        font-weight: lighter;
        font-weight: 400;

        @media screen and (max-width: 768px) {
          font-size: 1.2rem;
        }
      }

      .touch-list {
        margin-top: 1rem;

        .list {
          display: flex;
          align-items: center;
          margin: 1rem 0rem;
          gap: 1rem;

          img {
            width: 2rem;
            height: 2rem;
            object-fit: cover;
          }

          span {
            display: inline-block;
            color: var(--gray-color);
            @media screen and (max-width: 768px) {
              font-size: 0.8rem;
            }
          }
        }
      }
    }
  }

  /* Contact get touch */
  .get-touch-contact-container {
    display: flex;
    gap: 2rem;
    padding: 0rem 3rem;

    @media screen and (max-width: 768px) {
      flex-direction: column-reverse;
      padding: 0rem 1rem;
    }

    .get-touch-map {
      flex: 1 0 50%;
    }

    .get-touch-contact {
      width: 100%;
      flex: 1 0 50%;

      h3 {
        font-size: 2rem;
        font-weight: 400;
      }

      .contact-form {
        margin-top: 1rem;

        .input-control {
          margin: 2rem 0rem 0rem 0rem;

          input {
            width: 95%;
            border: none;
            box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.15);
            border-radius: 0.5rem;
            padding: 1rem 2rem;
            font-family: inherit;

            @media screen and (max-width: 768px) {
              width: 100%;
            }

            &:focus {
              outline: none;
            }
          }

          textarea {
            width: 95%;
            padding: 1rem 2rem;
            font-family: inherit;
            border: none;
            box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.15);
            border-radius: 0.5rem;

            @media screen and (max-width: 768px) {
              width: 100%;
            }

            &:focus {
              outline: none;
            }
          }
        }

        .input-btn {
          margin-top: 2rem;

          button {
            width: 95%;
            border: none;
            padding: 1rem 2rem;
            border-radius: 0.5rem;
            background-color: var(--blue-color);
            color: white;
            font-size: 1.3rem;
            font-family: inherit;
            font-weight: bold;
            cursor: pointer;

            @media screen and (max-width: 768px) {
              width: 100%;
            }
          }
        }
      }
    }
  }
`;

export const HideDiv = styled(motion.div)`
  overflow: hidden;
`;

/** @format */

import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Toaster } from "react-hot-toast";

import { Navbar, Footer } from "./components";
import {
  HomePage,
  Products,
  ProductDetail,
  AllProducts,
  Certificates,
} from "./pages";

import { MainStyle } from "./global.style";
import Modal from "./components/Modal/Modal";
import AboutPage from "./pages/AboutPage/AboutPage";

const App = () => {
  return (
    <BrowserRouter>
      <Navbar />
      <MainStyle>
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/productdetail/:id" element={<ProductDetail />} />
          <Route path="/products/:subid" element={<Products />} />
          <Route path="/allproducts" element={<AllProducts />} />
          <Route path="modal/:id" element={<Modal />} />
          <Route path="/about" element={<AboutPage />} />
          <Route path="/certificates" element={<Certificates />} />
        </Routes>
      </MainStyle>
      <Footer />
      <Toaster position="top-right" duration={4000}/>
    </BrowserRouter>
  );
};

export default App;

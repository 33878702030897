import styled from "styled-components";

import { motion } from "framer-motion";

export const SidebarContainerStyle = styled(motion.div)`
  position: fixed;
  width: 100%;
  height: 100vh;
  top: 4rem;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  z-index: 8000;
`;

export const SideBarNavStyle = styled(motion.div)`
  width: 80%;
  height: 100vh;
  background-color: white;
  padding: 0rem 1rem;
  position: relative;
  overflow-y: scroll;

  ul {
    li {
      margin: 1rem 0rem 0rem 0rem;
      a {
        text-decoration: none;
        color: black;
      }
    }
  }

  .sub-link {
    text-decoration: none;
    color: var(--gray-color);

    h5 {
      margin: 0.5rem 0rem 0rem 0.5rem;
      font-weight: 400;
      text-transform: capitalize;
    }
  }

  .goc-links {
    margin-top: 0.5rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;

    a {
      text-decoration: none;
      color: var(--gray-color);
      margin-left: 1rem;
      font-size: 0.8rem;
    }
  }
`;

import React from "react";

import { FileViewerStyle } from "./fileviewer.style";

import { IMG_URL } from "../../utils/Constants";

const FileViewer = ({ setIsViewOpen, certificatePath }) => {
  return (
    <FileViewerStyle onClick={() => setIsViewOpen(false)}>
      <img src={IMG_URL + certificatePath} alt="certificate" />
    </FileViewerStyle>
  );
};

export default FileViewer;

/** @format */

import styled from "styled-components";
import bgPic from "../../assets/aboutPage-bg.png";

export const Wrapper = styled.section`
  .about-page {
    margin: 8rem 0 0 0;

    .first-section {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      margin: 0 3rem 3rem 3rem;
      gap: 3rem;
      .first-section-title {
        display: flex;
        justify-content: center;
        margin: 0 0 1rem 0;
        h3 {
          font-weight: 500;
          font-size: 3rem;
          color: var(--blue-color);
        }
      }
      .first-section-row-1 {
        width: 50%;
        margin: 1rem 0 3rem 0;
        .first-section-row-1-center {
          display: flex;
          justify-content: center;
          align-items: center;
          text-align: center;
          p {
            font-weight: 400;
            font-size: 1rem;
            color: #565656;
            line-height: 2rem;
            .first-span {
              display: inline-block;
              color: red;
            }

            .second-span {
              display: inline-block;
              color: var(--blue-color);
            }
          }
        }
      }
      .first-section-row-2 {
        display: flex;
        justify-content: center;
        align-items: center;
        .first-section-row-2-left {
          width: 140%;
          display: flex;
          justify-content: center;
          align-items: center;
          img {
            width: 100%;
          }
        }
        .first-section-row-2-right {
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 0 0 0 3rem;
          p {
            font-weight: 400;
            font-size: 1rem;
            color: #565656;
            line-height: 2rem;
          }
        }
      }
      .first-section-row-3 {
        display: flex;
        justify-content: center;
        align-items: center;
        .first-section-row-3-left {
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 0 3rem 0 0;
          p {
            font-weight: 400;
            font-size: 1rem;
            color: #565656;
            line-height: 2rem;
          }
        }
        .first-section-row-3-right {
          width: 140%;
          display: flex;
          justify-content: center;
          align-items: center;
          img {
            width: 100%;
          }
        }
      }
    }
    .second-section {
      background-color: var(--faint-blue);
      margin: 0 0 0 0;
      padding: 2rem 3rem 2rem 3rem;
      .second-section-title {
        display: flex;
        align-items: center;
        h3 {
          font-weight: 600;
          font-size: 2rem;
          color: var(--blue-color);
        }
      }
      .second-section-body {
        margin: 1rem 0 0 0;
        p {
          margin: 0.5rem 0 0.5rem 0;
          color: #2e2e2e;
        }
      }
    }
    .third-section {
      margin: 0 0 0 0;
      padding: 2rem 3rem 2rem 3rem;
      .third-section-title {
        display: flex;
        align-items: center;
        h3 {
          font-weight: 600;
          font-size: 2rem;
          color: var(--blue-color);
        }
      }
      .third-section-body {
        margin: 1rem 0 0 0;
        p {
          margin: 0.5rem 0 0.5rem 0;
          color: #2e2e2e;
        }
      }
    }

    .fourth-section {
      background-color: red;
      background: url(${bgPic}) center/cover no-repeat;
      height: 50vh;
      display: flex;
      align-items: center;
      padding: 0rem 3rem;
      position: relative;
      z-index: 2000;
      .overlay {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.5);
        z-index: -10;
      }
      .fourth-section-container {
        .fourth-section-container-p {
          p {
            font-weight: 600;
            font-size: 1rem;
            color: var(--white-color);
            text-align: center;
            line-height: 2rem;
          }
        }
        .fourth-section-container-h {
          h3 {
            color: var(--white-color);
            font-weight: 700;
            font-size: 1.5rem;
            text-align: center;
            margin: 1rem 0 0 0;
          }
        }
      }
    }

    .fifth-section {
      padding: 4rem 3rem 1rem 3rem;
      margin: 0rem 0 1rem 0;
      .fifth-section-title {
        h2 {
          text-align: center;
          font-size: 3rem;
        }
      }

      .fifth-section-container {
        margin-top: 6rem;

        .service-card {
          text-align: center;
          box-shadow: 0px 0px 38px rgba(41, 171, 226, 0.07);
          border: 1px solid rgba(41, 171, 226, 0.23);
          padding: 2rem 2rem;
          border-radius: 1rem;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          position: relative;

          .service-circle {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            background-color: white;
            width: 8rem;
            height: 8rem;
            border-radius: 50%;
            border: 1px solid rgba(41, 171, 226, 0.23);
            box-shadow: 0px 0px 21px rgba(41, 171, 226, 0.16);
            padding: 1rem 2rem;
            position: absolute;
            top: -4rem;

            img {
              width: 5rem;
              height: 5rem;
              object-fit: cover;
            }
          }

          h3 {
            margin-top: 4rem;
          }

          p {
            width: 20rem;
            color: var(--gray-color);
            margin-top: 1rem;
            @media screen and (max-width: 768px) {
              width: 100%;
            }
          }
        }
      }

      .fifth-section-title {
        h2 {
          font-weight: 700;
          font-size: 2rem;
          color: var(--blue-color);
        }
        h3 {
          font-weight: 400;
          font-size: 1.5rem;
          color: #2e2e2e;
          text-align: center;
          margin: 1rem 0 0 0;
        }
      }
      .fifth-section-container {
      }
    }

    .sixth-section {
      margin: 0rem 3rem 4rem 3rem;
      .sixth-section-container {
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        .sixth-section-title {
          margin: 0 0 1rem 0;
          h3 {
            font-weight: 700;
            font-size: 2rem;
            color: var(--blue-color);
          }
        }
        .sixth-section-body {
          width: 50%;
          p {
            font-size: 1.2rem;
            font-weight: 400;
            color: #2e2e2e;
          }
        }
      }
    }
    .seventh-section {
      display: flex;
      justify-content: space-around;
      align-items: center;
      margin: 4rem 0 4rem 0;
      .seventh-section-col-1 {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        .img-background {
          background-color: #ffffff;
          border-radius: 50%;
          width: 5rem;
          height: 5rem;
          display: flex;
          justify-content: center;
          align-items: center;
          border: 1px solid rgba(41, 171, 226, 0.23);
          box-shadow: 0px 0px 40px rgba(41, 171, 226, 0.16);
          img {
            width: 3rem;
          }
        }
        h3 {
          color: #ffffff;
          font-family: Roboto;
          font-size: 40px;
          font-style: normal;
          font-weight: 500;
          letter-spacing: 0.1em;
          margin: 1rem 0 0 0;
          text-shadow: -1.15px 1.15px 0 #29abe2, 1.15px 1.15px 0 #29abe2,
            1.15px -1.15px 0 #29abe2, -1.15px -1.15px 0 #29abe2;
        }
        h5 {
          font-size: 1rem;
          font-weight: 400;
        }
      }
      .seventh-section-col-2 {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        .img-background {
          background-color: #ffffff;
          border-radius: 50%;
          width: 5rem;
          height: 5rem;
          display: flex;
          justify-content: center;
          align-items: center;
          border: 1px solid rgba(41, 171, 226, 0.23);
          box-shadow: 0px 0px 40px rgba(41, 171, 226, 0.16);
          img {
            width: 3rem;
          }
        }
        h3 {
          color: #ffffff;
          font-family: Roboto;
          font-size: 40px;
          font-style: normal;
          font-weight: 500;
          letter-spacing: 0.1em;
          margin: 1rem 0 0 0;
          text-shadow: -1.15px 1.15px 0 #29abe2, 1.15px 1.15px 0 #29abe2,
            1.15px -1.15px 0 #29abe2, -1.15px -1.15px 0 #29abe2;
        }
        h5 {
          font-size: 1rem;
          font-weight: 400;
        }
      }
      .seventh-section-col-3 {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        .img-background {
          background-color: #ffffff;
          border-radius: 50%;
          width: 5rem;
          height: 5rem;
          display: flex;
          justify-content: center;
          align-items: center;
          border: 1px solid rgba(41, 171, 226, 0.23);
          box-shadow: 0px 0px 40px rgba(41, 171, 226, 0.16);
          img {
            width: 3rem;
          }
        }
        h3 {
          color: #ffffff;
          font-family: Roboto;
          font-size: 40px;
          font-style: normal;
          font-weight: 500;
          letter-spacing: 0.1em;
          margin: 1rem 0 0 0;
          text-shadow: -1.15px 1.15px 0 #29abe2, 1.15px 1.15px 0 #29abe2,
            1.15px -1.15px 0 #29abe2, -1.15px -1.15px 0 #29abe2;
        }
        h5 {
          font-size: 1rem;
          font-weight: 400;
        }
      }
      .seventh-section-col-4 {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        .img-background {
          background-color: #ffffff;
          border-radius: 50%;
          width: 5rem;
          height: 5rem;
          display: flex;
          justify-content: center;
          align-items: center;
          border: 1px solid rgba(41, 171, 226, 0.23);
          box-shadow: 0px 0px 40px rgba(41, 171, 226, 0.16);
          img {
            width: 3rem;
          }
        }
        h3 {
          color: #ffffff;
          font-family: Roboto;
          font-size: 40px;
          font-style: normal;
          font-weight: 500;
          letter-spacing: 0.1em;
          margin: 1rem 0 0 0;
          text-shadow: -1.15px 1.15px 0 #29abe2, 1.15px 1.15px 0 #29abe2,
            1.15px -1.15px 0 #29abe2, -1.15px -1.15px 0 #29abe2;
        }
        h5 {
          font-size: 1rem;
          font-weight: 400;
        }
      }
    }
  }

  @media only screen and (max-width: 1000px) {
    .first-section {
      .first-section-title {
        h3 {
        }
      }
      .first-section-row-1 {
        width: 70% !important;
        .first-section-row-1-center {
          p {
          }
        }
      }
    }
    .sixth-section {
      .sixth-section-container {
        .sixth-section-title {
          h3 {
          }
        }
        .sixth-section-body {
          width: 70% !important;
          p {
          }
        }
      }
    }
  }

  @media only screen and (max-width: 768px) {
    .about-page {
      .first-section {
        gap: 1rem;
        .first-section-title {
          h3 {
            font-size: 1.8rem;
          }
        }
        .first-section-row-1 {
          width: 100% !important;
          .first-section-row-1-center {
            p {
              font-size: 0.8rem;
            }
          }
        }
        .first-section-row-2 {
          flex-direction: column;
          .first-section-row-2-left {
            width: 100%;
            img {
              width: 100%;
            }
          }
          .first-section-row-2-right {
            padding: 0 0 0 0;
            p {
              text-align: center;
              margin: 2rem 0;
              font-size: 0.8rem;
            }
          }
        }
        .first-section-row-3 {
          flex-direction: column-reverse;
          .first-section-row-3-left {
            padding: 0 0 0 0;
            p {
              text-align: center;
              margin: 2rem 0;
              font-size: 0.8rem;
            }
          }
          .first-section-row-3-right {
            width: 100%;
            img {
              width: 100%;
            }
          }
        }
      }

      .second-section {
        padding: 2rem 2rem 2rem 2rem;
        .second-section-title {
          h3 {
            font-size: 1.2rem;
          }
        }
        .second-section-body {
          margin: 1rem 0 0 0;
          p {
            margin: 0.5rem 0 0.5rem 0;
            color: #2e2e2e;
            font-size: 0.8rem;
          }
        }
      }

      .third-section {
        padding: 2rem 2rem 2rem 2rem;
        .third-section-title {
          h3 {
            font-size: 1.2rem;
          }
        }
        .third-section-body {
          p {
            font-size: 0.8rem;
          }
        }
      }

      .fourth-section {
        height: 50vh;
        .overlay {
        }
        .fourth-section-container {
          .fourth-section-container-p {
            p {
              font-size: 0.9rem;
              line-height: 1.2rem;
            }
          }
          .fourth-section-container-h {
            h3 {
              font-size: 1rem;
            }
          }
        }
      }

      .fifth-section {
        .fifth-section-title {
          h2 {
            font-size: 1.2rem;
          }
          h3 {
            font-size: 0.8rem;
          }
        }

        .fifth-section-container {
          .service-card {
            .service-circle {
              img {
              }
            }

            h3 {
            }

            p {
            }
          }
        }
      }

      .fifth-section-title {
        h2 {
        }
        h3 {
        }
      }
      .fifth-section-container {
      }
    }

    .sixth-section {
      .sixth-section-container {
        .sixth-section-title {
          h3 {
            font-size: 1.2rem !important;
          }
        }
        .sixth-section-body {
          width: 100% !important;
          p {
            font-size: 0.8rem !important;
          }
        }
      }
    }
    .seventh-section {
      flex-wrap: wrap;
      /* justify-content: space-around; */
      gap: 2rem;
      margin: 0 1rem 4rem 0 !important;
      /* padding: 0 0.2rem; */
      .seventh-section-col-1 {
        width: 8rem;
      }
      .seventh-section-col-2 {
        width: 8rem;
      }
      .seventh-section-col-3 {
        width: 8rem;
      }
      .seventh-section-col-4 {
        width: 8rem;
      }
    }
  }
`;

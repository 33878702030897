import styled from "styled-components";

export const NavBarContainerStyle = styled.nav`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: ${(props) =>
    props.isScrolled
      ? "1rem 3rem"
      : "1rem 3rem"
      ? props.pathname !== "/"
        ? "1rem 3rem"
        : "1rem 3rem"
      : ""};
  position: fixed;
  top: 0;
  width: 100%;
  background: ${(props) => (props.isScrolled ? "white" : "transparent")};
  box-shadow: ${(props) =>
    props.isScrolled
      ? "0px 4px 19px rgba(0, 0, 0, 0.2)"
      : "none"
      ? props.pathname !== "/"
        ? "0px 4px 19px rgba(0, 0, 0, 0.2)"
        : "none"
      : ""};
  transition: all 0.5s ease-in-out;
  z-index: ${(props) => (props.pathname === "/" ? "5000" : "8000")};

  @media screen and (max-width: 768px) {
    padding: 0.5rem 1rem;
    background: ${(props) =>
      props.pathname !== "/"
        ? "white"
        : props.isScrolled
        ? "white"
        : "transparent"};
  }
`;

export const NavLeftStyle = styled.div`
  display: flex;
  justify-content: space-between;

  gap: 3rem;

  .logo {
    img {
      width: 12rem;
      height: auto;
      object-fit: contain;
    }
  }

  ul {
    display: flex;
    gap: 2rem;
    align-items: center;
    position: relative;

    @media screen and (max-width: 768px) {
      display: none;
    }

    li > a {
      transition: 0.2s all ease-in-out;
      text-decoration: none;
      font-size: 1.2rem;
      color: ${(props) =>
        props.isScrolled
          ? "#000000"
          : "white"
          ? props.pathname !== "/"
            ? "#000000"
            : "white"
          : ""};

      &:hover {
        color: var(--blue-color);
      }
    }
  }
`;

export const NavRightStyle = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  a {
    text-decoration: none;
    border: ${(props) =>
      props.isScrolled
        ? "0.1rem solid var(--blue-color)"
        : "0.1rem solid white"
        ? props.pathname !== "/"
          ? "0.1rem solid var(--blue-color)"
          : "0.1rem solid white"
        : ""};
    font-family: inherit;
    font-weight: bold;
    font-size: 1.3rem;
    padding: 0.5rem 1rem;
    color: ${(props) =>
      props.isScrolled
        ? "var(--blue-color)"
        : "white"
        ? props.pathname !== "/"
          ? "var(--blue-color)"
          : "white"
        : ""};
    border-radius: 0.5rem;
    background: transparent;
    cursor: pointer;

    @media screen and (max-width: 768px) {
      display: none;
    }
  }
`;

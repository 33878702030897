/** @format */

import styled from "styled-components";

export const Wrapper = styled.section`
  .card {
    box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.1);
    border-radius: 7px;
    padding: 0rem 0;
    margin: 2rem 0rem 2rem 0rem;
    position: relative;
    .card-img {
      padding: 0 0 0rem 0;
      border-bottom: 1px solid #ebebeb;
      display: flex;
      .mySwiper {
        img {
          width: 100%;
          height: 200px;
          object-fit: cover;
          border-top-left-radius: 7px;
          border-top-right-radius: 7px;

          @media screen and (max-width: 768px) {
            
          }
        }
        .swiper-pagination {
          position: absolute;
          bottom: 0rem;
        }
      }
    }
    .card-title {
      h3 {
        font-weight: 400;
        font-size: 1rem;
        color: #4d4d4d;
        margin: 1rem 1rem;
      }
    }
    .card-price {
      h3 {
        font-weight: 500;
        font-size: 0.9rem;
        color: #2e2e2e;
        margin: 1rem 1rem;
      }
    }
    .card-rating {
      position: absolute;
      bottom: 7.5rem;

      h3 {
        font-weight: 500;
        font-size: 0.6rem;
        margin: 0rem 1rem;
        color: #414141;
      }
    }
    .card-button {
      position: relative;
      top: 1rem;
      width: 100%;
      display: flex;
      justify-content: center;
      button {
        font-weight: 500;
        font-size: 0.8rem;
        color: var(--blue-color);
        background: #ffffff;
        box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.1);
        border-radius: 7px;
        border: none;
        width: 12rem;
        height: 2.8rem;
        cursor: pointer;
      }
    }
  }

  @media only screen and (max-width: 690px) {
    .card {
      box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.1);
      border-radius: 7px;
      position: relative;
      .card-img {
        padding: 1rem 0rem 1rem 0rem;
        border-bottom: 1px solid #ebebeb;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 12rem;
        .mySwiper {
          display: flex;
          justify-content: center;
          align-items: center;
          img {
            
          }
          .swiper-pagination {
            position: absolute;
            bottom: 0px;
          }
        }
      }
      .card-title {
        h3 {
          font-weight: 400;
          font-size: 0.7rem;
          color: #4d4d4d;
          margin: 0.5rem 0.5rem;
        }
      }
      .card-price {
        h3 {
          font-weight: 500;
          font-size: 0.7rem;
          color: #2e2e2e;
          margin: 0rem 0.5rem;
        }
      }
      .card-rating {
        position: absolute;
        bottom: 15.5rem;
        z-index: 7998;
        right: 0rem;
        h3 {
          font-weight: 500;
          font-size: 0.6rem;
          margin: 0rem 0.5rem;
        }
      }
      
    }
  }
`;

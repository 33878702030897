/** @format */

import axios from "axios";
import { motion } from "framer-motion";
import React, { useEffect, useState, useRef, useLayoutEffect } from "react";
import { toast } from "react-hot-toast";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import { Autoplay } from "swiper";

import gmailImg from "../../assets/gmailIcon.png-removebg-preview.png";
import mailImg from "../../assets/email-pr-icon.png";
import callImg from "../../assets/phone-call-pr-icon.png";
import skypeIcon from "../../assets/skype-pr-icon.png";
import facebookImg from "../../assets/Facebook_Logo_(2019).png";
import linkedinImg from "../../assets/LinkedIn_icon_circle.svg.png";

import { getEndpoints, IMG_URL, postEndpoints } from "../../utils/Constants";
import {
  GetInTouchContainerStyle,
  HeroImgStyle,
  HeroSecStyle,
  HideDiv,
  ItroContentStyle,
  OurServiceStyle,
  QuoteSecStyle,
  WhoWeAreStyle,
  WrapperStyle,
} from "./homepage.style";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { useQuery } from "@tanstack/react-query";
import { fetchAllHomeScreens, fetchAllServices } from "../../utils/APIUrls";

gsap.config({
  nullTargetWarn: false,
});
gsap.registerPlugin(ScrollTrigger);

const HomePage = () => {
  let initialData = {
    name: "",
    email: "",
    mobile_no: "",
    subject: "",
    message: "",
  };

  const { data: homescreens } = useQuery({
    queryKey: ["homescreens"],
    queryFn: fetchAllHomeScreens,
  });

  const { data: ourServices } = useQuery({
    queryKey: ["services"],
    queryFn: fetchAllServices,
  });

  const [getInTouch, setGetInTouch] = useState();
  const [whoWeAre, setWhoWeAre] = useState();
  const [whoWeareImg, setWhoWeareImg] = useState();
  const [isScrolled, setIsScrolled] = useState(false);

  const [contactData, setContactData] = useState(initialData);
  const [loading, setLoading] = useState(false);
  const [heroImages, setHeroImages] = useState([]);

  const [isHoveredFacebook, setIsHoveredFacebook] = useState(false);
  const [isHoveredLinkedin, setIsHoveredLinkedin] = useState(false);
  const [isHoveredEmail, setIsHoveredEmail] = useState(false);

  const [matches, setMatches] = useState(
    window.matchMedia("(max-width: 768px)").matches
  );

  const serviceContainerRef = useRef(null);
  const cardRefs = useRef([]);
  cardRefs.current = [];
  const swiperRef = useRef();

  const { name, email, mobile_no, subject, message } = contactData;

  const validateInputs = () => {
    let validateName = /^[A-Za-z.-]+(\s*[A-Za-z.-]+)*$/;
    let validateEmail = /[^@ \t\r\n]+@[^@ \t\r\n]+\.[^@ \t\r\n]+/;
    let validateMobNo =
      /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/;

    if (!validateName.test(name)) {
      setLoading(false);
      toast.error("Please enter valid name");
      return false;
    }

    if (!validateEmail.test(email)) {
      setLoading(false);
      toast.error("Please enter valid email");
      return false;
    }

    if (!validateMobNo.test(mobile_no)) {
      setLoading(false);
      toast.error("Please enter valid mobile no");
      return false;
    }

    return true;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    setLoading(true);

    let payload = {
      name,
      email,
      mobile_no,
      subject,
      message,
    };

    try {
      if (validateInputs()) {
        const response = await axios.post(postEndpoints.postContact, payload);

        if (response.data.status === "success") {
          setLoading(false);
          toast.success(response.data.message);
          clearForm();
        } else {
          setLoading(false);
          toast.error(response.data.message);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleContactChange = (e) => {
    setContactData((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  const clearForm = () => {
    setContactData((prevState) => ({
      ...prevState,
      name: "",
      email: "",
      mobile_no: "",
      subject: "",
      message: "",
    }));
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useLayoutEffect(() => {
    if (!matches) {
      let ctx = gsap.context(() => {
        gsap.to(cardRefs.current, {
          xPercent: () => -100 * (cardRefs.current.length - 1),
          ease: "none",
          scrollTrigger: {
            trigger: serviceContainerRef.current,
            pin: true,
            pinSpacing: true,
            scrub: 1,
            anticipatePin: 1,
            snap: 1 / (cardRefs.current.length - 1),
            end: () => "+=" + serviceContainerRef.current.offsetWidth,
          },
        });
      }, serviceContainerRef);

      return () => ctx.revert();
    }
  }, [ourServices?.data?.docs]);

  const createCardRefs = (card) => {
    if (card && !cardRefs.current.includes(card)) {
      cardRefs.current.push(card);
    }
  };

  const fetchTouch = async () => {
    const res = await axios.get(getEndpoints.getAllGetInTouch);
    setGetInTouch(res.data);
  };

  const fetchWhoWeAre = async () => {
    const res = await axios.get(getEndpoints.getAllWhoWeAre);
    setWhoWeAre(res.data);
    setWhoWeareImg(res?.data?.data?.docs[0].image);
  };

  useEffect(() => {
    fetchTouch();
    fetchWhoWeAre();
  }, []);

  const handleNavbar = () => {
    if (window.scrollY > 50) {
      setIsScrolled(true);
    } else {
      setIsScrolled(false);
    }
  };

  window.addEventListener("scroll", handleNavbar);

  const enterHoverEmail = () => {
    setIsHoveredEmail(true);
  };

  const leaveHoverEmail = () => {
    setIsHoveredEmail(false);
  };

  const enterHoverLinkedin = () => {
    setIsHoveredLinkedin(true);
  };

  const leaveHoverLinkedin = () => {
    setIsHoveredLinkedin(false);
  };

  const enterHoverFacebook = () => {
    setIsHoveredFacebook(true);
  };

  const leaveHoverFacebook = () => {
    setIsHoveredFacebook(false);
  };

  return (
    <section>
      <WrapperStyle
        isScrolled={isScrolled}
        isHoveredEmail={isHoveredEmail}
        isHoveredLinkedin={isHoveredLinkedin}
        isHoveredFacebook={isHoveredFacebook}
      >
        <div className="sticky-social-media">
          <motion.div
            whileHover={{ width: 100 }}
            whileTap={{
              scale: 0.8,
            }}
            className="sticky-email"
            onMouseEnter={enterHoverEmail}
            onMouseLeave={leaveHoverEmail}
          >
            <img src={gmailImg} alt="" />
            <a
              href="https://mail.google.com/mail/?view=cm&fs=1&to=export@prpaperindustries.com"
              target="_blank"
              rel="noreferrer"
            >
              <h5>Contact Us</h5>
            </a>
          </motion.div>
          <motion.div
            whileHover={{ width: 100 }}
            whileTap={{
              scale: 0.8,
            }}
            className="sticky-linkedin"
            onMouseEnter={enterHoverLinkedin}
            onMouseLeave={leaveHoverLinkedin}
          >
            <img src={linkedinImg} alt="" />
            <a
              href="https://www.linkedin.com/company/pr-global-resources-india-pvt-ltd/?originalSubdomain=in"
              target="_blank"
              rel="noreferrer"
            >
              <h5>Contact Us</h5>
            </a>
          </motion.div>
          <motion.div
            whileHover={{ width: 100 }}
            whileTap={{
              scale: 0.8,
            }}
            className="sticky-facebook"
            onMouseEnter={enterHoverFacebook}
            onMouseLeave={leaveHoverFacebook}
          >
            <img src={facebookImg} alt="" />
            <a
              href="https://www.facebook.com/profile.php?id=100083133087010"
              target="_blank"
              rel="noreferrer"
            >
              <h5>Contact Us</h5>
            </a>
          </motion.div>
        </div>
      </WrapperStyle>
      <HeroSecStyle>
        <Swiper
          autoplay={{
            delay: 5000,
            disableOnInteraction: false,
          }}
          loop={true}
          modules={[Autoplay]}
        >
          {homescreens?.data?.docs.length > 0 && (
            <>
              {homescreens?.data?.docs.map((screen) => (
                <SwiperSlide key={screen?._id}>
                  <ItroContentStyle>
                    <h1>{screen?.title}</h1>

                    <p
                      dangerouslySetInnerHTML={{ __html: screen?.description }}
                    ></p>

                    <div className="btn-container">
                      <a href="#getintouch">Get in Touch</a>
                      <div className="arrow-line"></div>
                    </div>
                  </ItroContentStyle>
                  <HeroImgStyle>
                    <div className="overlay"></div>
                    <img src={IMG_URL + screen?.image} alt={screen?.title} />
                  </HeroImgStyle>
                </SwiperSlide>
              ))}
            </>
          )}
        </Swiper>
      </HeroSecStyle>
      {/* Who We are */}
      <WhoWeAreStyle>
        <HideDiv
          initial={{ x: -200, opacity: 0 }}
          whileInView={{ x: 0, opacity: 1 }}
          viewport={{ once: true }}
          transition={{ duration: 0.75, ease: "easeOut" }}
        >
          <motion.h2>Who we are</motion.h2>
        </HideDiv>
        <div className="who-we-are-container">
          <div className="left-content-text">
            <motion.p
              initial={{ opacity: 0 }}
              whileInView={{ opacity: 1 }}
              transition={{ duration: 0.75 }}
              viewport={{ once: true }}
              dangerouslySetInnerHTML={{
                __html: whoWeAre?.data?.docs[0].description,
              }}
            ></motion.p>
          </div>
          <div className="right-content-img">
            <motion.img
              initial={{ opacity: 0 }}
              whileInView={{ opacity: 1 }}
              transition={{ duration: 0.75 }}
              viewport={{ once: true }}
              src={IMG_URL + whoWeareImg}
              alt="Who we are"
            />
          </div>
        </div>
      </WhoWeAreStyle>
      {/* Our Services */}
      {matches ? (
        <OurServiceStyle>
          <HideDiv
            initial={{ x: -200, opacity: 0 }}
            whileInView={{ x: 0, opacity: 1 }}
            transition={{ duration: 0.75, ease: "easeOut" }}
            viewport={{ once: true }}
          >
            <motion.h2>Our Services</motion.h2>
          </HideDiv>
          <div className="services-container">
            {ourServices?.data?.docs?.map((service) => (
              <div className="service-card" key={service?._id}>
                <div className="service-circle">
                  <img src={IMG_URL + service?.icon} alt={service?.title} />
                </div>
                <h3>{service?.title}</h3>
                <p
                  dangerouslySetInnerHTML={{ __html: service?.description }}
                ></p>
              </div>
            ))}
          </div>
        </OurServiceStyle>
      ) : (
        <OurServiceStyle ref={serviceContainerRef}>
          <HideDiv
            initial={{ x: -200, opacity: 0 }}
            whileInView={{ x: 0, opacity: 1 }}
            viewport={{ once: true }}
            transition={{ duration: 0.75, ease: "easeOut" }}
          >
            <motion.h2>Our Services</motion.h2>
          </HideDiv>
          <div className="services-container">
            {ourServices?.data?.docs?.map((service) => (
              <div
                className="service-card"
                ref={createCardRefs}
                key={service?._id}
              >
                <div className="service-circle">
                  <img src={IMG_URL + service?.icon} alt={service?.title} />
                </div>
                <h3>{service?.title}</h3>
                <p
                  dangerouslySetInnerHTML={{ __html: service?.description }}
                ></p>
              </div>
            ))}
          </div>
        </OurServiceStyle>
      )}
      {/* <OurServiceStyle ref={serviceContainerRef}>
        <HideDiv
          initial={{ x: -200, opacity: 0 }}
          whileInView={{ x: 0, opacity: 1 }}
          transition={{ duration: 0.75, ease: "easeOut" }}
        >
          <motion.h2>Our Services</motion.h2>
        </HideDiv>
        <div className="services-container">
          {ourServices?.data?.docs?.map((service) => (
            <div
              className="service-card"
              ref={createCardRefs}
              key={service?._id}
            >
              <div className="service-circle">
                <img src={IMG_URL + service?.icon} alt={service?.title} />
              </div>
              <h3>{service?.title}</h3>
              <p dangerouslySetInnerHTML={{ __html: service?.description }}></p>
            </div>
          ))}
        </div>
      </OurServiceStyle> */}
      {/* Quote Section */}
      <QuoteSecStyle>
        <div className="quote-overlay"></div>
        <HideDiv
          initial={{ y: 200, opacity: 0 }}
          whileInView={{ y: 0, opacity: 1 }}
          transition={{ duration: 0.75, ease: "easeOut" }}
          viewport={{ once: true }}
        >
          <h2>
            “We are an established and trusted Import Export Agency with a
            reputation for commitment and high integrity”
          </h2>
        </HideDiv>
      </QuoteSecStyle>
      {/* Get in Touch */}
      <GetInTouchContainerStyle id="getintouch">
        <div className="get-touch-header">
          <HideDiv
            initial={{ x: -200, opacity: 0 }}
            whileInView={{ x: 0, opacity: 1 }}
            viewport={{ once: true }}
            transition={{
              duration: 0.75,
              ease: "easeOut",
            }}
          >
            <h2>Get in Touch</h2>
          </HideDiv>
          <motion.p
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            transition={{ duration: 0.75 }}
            viewport={{ once: true }}
          >
            Consumer Awareness is an act of making sure the buyer or consumer is
            aware of the information about products, goods, services, and
            consumers rights. Consumer awareness is important so that buyer can
            take the right decision and make the right choice
          </motion.p>
        </div>

        <div className="get-touch-category-container">
          {getInTouch?.data?.docs.map((curElem, index) => {
            // console.log(curElem);
            const { title, mobile_no, email1, email2, email3 } = curElem;
            return (
              <motion.div
                initial={{ opacity: 0 }}
                whileInView={{ opacity: 1 }}
                transition={{ duration: 0.75 }}
                viewport={{ once: true }}
                className="get-touch-card"
                key={index}
              >
                <h2>{title}</h2>
                <div className="touch-list">
                  <div className="list">
                    <a href={"whatsapp://send?phone=" + mobile_no}>
                      {" "}
                      <img src={callImg} alt="phone icon" />
                    </a>
                    <a href={"tel:" + mobile_no}>
                      {" "}
                      <span>{mobile_no}</span>
                    </a>
                  </div>
                  <div className="list">
                    <img src={mailImg} alt="mail icon" />
                    <a href={"mailTo:" + email1}>
                      {" "}
                      <span>{email1}</span>
                    </a>
                  </div>
                  <div className="list">
                    <img src={mailImg} alt="mail icon" />
                    <a href={"mailTo:" + email2}>
                      {" "}
                      <span>{email2}</span>
                    </a>
                  </div>
                  <div className="list">
                    <img src={mailImg} alt="skype icon" />
                    <a href={"mailTo:" + email3}>
                      {" "}
                      <span>{email3}</span>
                    </a>
                  </div>
                </div>
              </motion.div>
            );
          })}
        </div>

        <div className="get-touch-contact-container" id="contact">
          <div className="get-touch-map">
            <iframe
              title="prglobal"
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d7445.291386648808!2d79.07267735390627!3d21.086808699999995!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bd4bf63b33018fb%3A0x652a231a734afc0e!2sPR%20PAPER%20INDUSTIRES%20INDIA%20PRIVATE%20LIMITED.!5e0!3m2!1sen!2sin!4v1674634477256!5m2!1sen!2sin"
              width="100%"
              height="100%"
              allowFullScreen=""
              loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"
            ></iframe>
          </div>
          <div className="get-touch-contact">
            <h3>Drop a note for us</h3>
            <div className="contact-form">
              <form onSubmit={handleSubmit}>
                <div className="input-control">
                  <input
                    type="text"
                    placeholder="Name"
                    name="name"
                    value={name}
                    required
                    onChange={handleContactChange}
                  />
                </div>
                <div className="input-control">
                  <input
                    type="email"
                    onChange={handleContactChange}
                    name="email"
                    placeholder="Email"
                    value={email}
                    required
                  />
                </div>
                <div className="input-control">
                  <input
                    type="text"
                    name="mobile_no"
                    value={mobile_no}
                    placeholder="Contact No"
                    maxLength={10}
                    required
                    onChange={handleContactChange}
                  />
                </div>
                <div className="input-control">
                  <input
                    type="text"
                    name="subject"
                    value={subject}
                    onChange={handleContactChange}
                    placeholder="Subject"
                  />
                </div>
                <div className="input-control">
                  <textarea
                    name="message"
                    value={message}
                    onChange={handleContactChange}
                    rows={5}
                    placeholder="Message"
                  />
                </div>

                <div className="input-btn">
                  <button type="submit">
                    {loading ? "Submitting..." : "Send Message"}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </GetInTouchContainerStyle>
    </section>
  );
};

export default HomePage;
